import {NotificationService} from './../../services/notification.service';
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {LoginService} from '../../components/login-service';
import {DialogService} from '../../services/dialog.service';
import {SearchBoxService} from '../../services/search-box.service';
import {Router} from '@angular/router';
import {PlatformLocation} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Notification} from 'src/app/models/notification.model';
import {environment} from 'src/environments/environment';
import {Category} from '@models';


@Component({
  selector: 'app-header',
  templateUrl: environment.multisite ? '/src/multisite/' + environment.multisite + '/layouts/header/header.component.html' : './header.component.html',
  //templateUrl: '/src/multisite/sosimpacto/layouts/header/header.component.html',
  styleUrls: []
})


export class HeaderComponent implements OnInit {
  absoluteURL: string;
  IsEnglish = false;
  user: Keycloak.KeycloakProfile;
  badgeContent: number;
  notifications: Observable<Notification[]>;
  public logo = 'assets/images/' + environment.store + '.svg';

  // HTML Demo
  @Output() public sidenavToggle = new EventEmitter();

  @Input()
  public categories: Category[];

  constructor(private loginService: LoginService,
              private dialogService: DialogService,
              public searchBoxService: SearchBoxService,
              private platformLocation: PlatformLocation,
              public router: Router,
              public notificationService: NotificationService,
              public dialog: MatDialog) {


    this.loginService.user$.subscribe(user => {
      this.user = user;
      if (user != null) {
        let path = environment.apiBase + '/aplicaciones/' + environment.keycloakConfig.realm
          + '/tenants/' + environment.keycloakConfig.clientId
          + '/notificaciones/usuarioWeb?desplazamiento=0&limite=5&oculto=false';
        this.notificationService.obtenerTodos(path).subscribe(data => {
          this.notifications = data.notificaciones;
          this.badgeContent = data.total;
        });
      }

    });
    this.absoluteURL = environment.urlApp;
  }

  ngOnInit(): void {
    const url = this.platformLocation.href;
    const base = environment.urlApp;
    const lang = url.substr(base.length + url.indexOf(base), 2);
    if (lang === 'es') {
      this.IsEnglish = false;
    } else {
      this.IsEnglish = true;
    }
  }

  logOut() {
    const url = location.href;
    const urlArguments = url.substr(environment.urlApp.length);
    const lang = urlArguments.substr(0, 2);
    this.loginService.logOut(environment.urlApp + lang);
  }

  openDialog() {
    this.dialogService.open();
  }

  login() {
    this.loginService.login();
  }

  // changePassword(){
  //   this.
  // }

  register() {
    this.loginService.login();
    //this.loginService.register();
  }

  openAthenaeums() {
    const url = 'https://ateneo-aleph.quanam.com/';
    window.open(url, '_blank');
  }

  verNotificaciones() {
    let path = environment.apiBase + '/aplicaciones/' + environment.keycloakConfig.realm
      + '/tenants/' + environment.keycloakConfig.clientId
      + '/notificaciones/usuarioWeb?desplazamiento=0&limite=5&oculto=false';
    this.notificationService.obtenerTodos(path).subscribe(data => {
      this.notifications = data.notificaciones;
      this.badgeContent = data.total;
    });
  }

  readNotificationCheck(notification: any, state: boolean) {
    this.notificationService.updateNotification(notification.id, 'leido', state).subscribe(
      (response) => {
        if (!state) {
          this.badgeContent--;
        } else {
          this.badgeContent++;
        }
        this.verNotificaciones();
      },
      (error) => console.log(error)
    );

  }

  hideNotificationCheck(notification: any, state: boolean) {
    this.notificationService.updateNotification(notification.id, 'oculto', state).subscribe(
      (response) => {
        this.badgeContent--;
        this.verNotificaciones();
      },
      (error) => console.log(error)
    );
  }

  decreaseCount() {
    if (this.badgeContent <= 0) {
      return;
    }
    this.badgeContent--;
  }

  openNotification(notification: Notification): void {

    const dialogRef = this.dialog.open(NotificationDialog, {
      height: '70%',
      data: notification
    });
  }


  changeLanguage(language: string) {
    // localStorage.setItem('locale', language);
    window.location.href = environment.urlApp + language;
  }

  // HTML demo
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

}

@Component({
  selector: 'app-notification-dialog',
  templateUrl: 'notification-dialog.html'
})
export class NotificationDialog {

  constructor(
    public dialogRef: MatDialogRef<NotificationDialog>,
    @Inject(MAT_DIALOG_DATA) public notification: Notification) {
  }

}

