<div class="search-hero mb-5">
  <div class="container">
    <form [formGroup]="searchForm" class="form-search" (ngSubmit)="onSubmit(searchForm.value)">
      <mat-form-field floatLabel="never" appearance="fill">
        <input matInput type="search" formControlName="search" placeholder="Find solutions...">
        <button type="submit" mat-icon-button aria-label="Search" matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
</div>

<ng-container *ngIf="(products$ | async)?.length > 0">
  <div class="search-results">
    <div class="container">
      <h1>Resultados:</h1>
      <p i18n="Message NotFound| El mensaje que resulta luego de que la busqueda muestra n resultados">There are <strong>{{(products$ | async)?.length}}</strong> applications that meet your search.</p>
    </div>
    <app-product-list [busqueda]="true" [products]="products$ | async"></app-product-list>
  </div>
</ng-container>

<ng-container *ngIf="(products$ | async)?.length === 0">
  <div class="search-results-empty">
    <div class="container">
      <mat-icon>warning</mat-icon>
      <p i18n="Message NotFound| El mensaje que resulta luego de que la busqueda no arroja resultados">No results found for your search</p>
    </div>
  </div>
</ng-container>