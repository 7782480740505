import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Category} from '@models';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LangService} from "./lang.service";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public categories: Category[];
  private categories$ = new BehaviorSubject<Category[]>([]);

  constructor(private httpClient: HttpClient, private langService: LangService) {
    let params = new HttpParams();
    params = params.append('lang', langService.getLang());

    this.httpClient.get<Category[]>(environment.apiBase + '/tiendas/' + environment.store + '/categorias/activos', {params}).pipe(
      tap((categories: Category[]) => {
          this.categories = categories;
          this.categories$.next(this.categories);
        }
      )
    ).subscribe();
  }

  public getAll$(): Observable<Category[]> {
    return this.categories$;
  }

  public getChildrens$(idCategory: string, idSubCategory?: string): Observable<Category[]> {
    return this.categories$.asObservable().pipe(
      map((categories: Category[]) => {
        const category = categories.find(c => c.idExterno === idCategory);
        return category ? category.categorias : [];
      })
    );
  }

  public getOne$(idCategory: string, idSubCategory?: string): Observable<Category> {
    return this.categories$.asObservable().pipe(
      map((categories: Category[]) => {
        let category = categories.find(c => c.idExterno === idCategory);
        if (idSubCategory && category) {
          category = category.categorias.find(c => c.idExterno === idSubCategory);
        }
        return category;
      })
    );
  }

  public getOneDeepSearch$(idExterno: string): Observable<Category> {
    return this.categories$.asObservable().pipe(
      map((categories: Category[]) => {
        let category = categories.find(c => c.idExterno === idExterno);
        if (category === undefined) {
          category = this.recursiveSearch(categories, idExterno);
        }
        return category;
      })
    );
  }

  private recursiveSearch(categorias: Category[], idExterno: string): Category {
    let category = categorias.find(c => c.idExterno === idExterno);
    if (category === undefined) {
      for (const cat of categorias) {
        if (cat.categorias !== undefined && cat.categorias.length > 0) {
          category = this.recursiveSearch(cat.categorias, idExterno);
        }
        if (category !== undefined) {
          break;
        }
      }
    }
    return category;
  }

  public getChildrenDeepSearch$(idExterno: string): Observable<Category[]> {
    return this.categories$.asObservable().pipe(
      map((categories: Category[]) => {
        let category = categories.find(c => c.idExterno === idExterno);
        if (category === undefined) {
          category = this.recursiveSearch(categories, idExterno);
        }
        return category?.categorias;
      })
    );
  }
}
