import {Component, OnInit} from '@angular/core';
import {CategoryService} from '@services';
import {AbstractPage} from '../abstract.page';
import {Category} from '@models';
import {SearchBoxService} from '../../services/search-box.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactFormService} from '../../services/contactForm.service';

@Component({
  selector: 'app-provider-page',
  templateUrl: environment.multisite ? '/src/multisite/' + environment.multisite + '/layouts/provider/provider-page.html' : './provider-page.html',
  //templateUrl: '/src/multisite/pulmrx/layouts/provider/provider-page.html',
  styleUrls: []
})
export class ProviderPage extends AbstractPage implements OnInit {

  public category: Category;
  public contactForm = new FormGroup({
    nombre: new FormControl('', Validators.compose([Validators.required])),
    apellido: new FormControl('', Validators.compose([Validators.required])),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.minLength(3)])),
    acceptTerms: new FormControl('', Validators.requiredTrue),
  });
  public submited = false;
  public loading =  false;

  constructor(
    private searchBoxService: SearchBoxService,
    private categoryService: CategoryService,
    private contactFormService: ContactFormService,
    route: ActivatedRoute) {
    super();
    this.searchBoxService.showSearchBox();

  }

  pageTitle(): string {
    return environment.title;
  }

  ngOnInit() {
    this.categoryService.getOneDeepSearch$(environment.homeCategory).subscribe(data => {
      this.category = data;
    });
  }

  sendContact() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.loading = true;
      this.contactFormService.send(this.contactForm.value).subscribe(() => {
        this.loading = false;
        this.submited = true;
      });
    }

  }

}
