<div class="appDetailsContent">
  <div class="breadcrumb"><!-- appCats -->
    <div class="container">
      <a [routerLink]="'/'" i18n="Home">Home</a>
      <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span i18n="Service orders">Service orders</span>
    </div>
  </div>
</div>
<div class="manageSubscriptionsContent" color="primary">
  <div class="container">
    <!--<div class="row">
      <div class="col-12">
        <div class="filtersSearch d-flex flex-column flex-md-row justify-content-md-end align-items-md-center">
          <button class="text-uppercase btSmall" mat-button>Mostrar Filtros</button>
          <form action="" class="searchSusbcriptions">
            <mat-form-field floatLabel="never" appearance="fill">
              <input matInput type="search" placeholder="">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>-->

    <mat-list class="list-orders" role="list">
      <mat-list-item *ngFor="let orden of this.ordenes" role="listitem">
        <mat-card class="order-card mat-elevation-z0">
          <mat-card-title-group>
            <img mat-card-image src="{{orden?.plan?.servicio?.imgUrl || orden?.contrato?.plan?.servicio?.imgUrl}}">
            <div>
              <mat-card-title>
                <span *ngIf="orden.tipo === 'ORDEN_COMPRA_PLAN'">
                  <span i18n="PurchaseOfTitle | Compra de - titulo ordenes de servicio">Purchase of</span> {{orden.plan.nombre}}</span>
                <span *ngIf="orden.tipo === 'ORDEN_CANCELACION_PLAN'">
                  <span i18n="CancelationOfTitle | Canelacion de - titulo ordenes de servicio">Cancellation of</span> {{orden?.contrato?.plan?.nombre}}</span>
                <span *ngIf="orden.tipo === 'ORDEN_COMPRA_COMPLEMENTO'">
                  <span i18n="PurchaseOfComplementTitle | Compra de - titulo ordenes de servicio para complementos">Purchase of add-on on</span> {{orden?.contrato?.plan?.nombre}}</span>
                <span *ngIf="orden.tipo === 'ORDEN_CANCELACION_COMPLEMENTO'">
                  <span i18n="CancelationOfComplementTitle | Canelacion de - titulo ordenes de servicio para complementos">Cancellation of add-on</span> {{orden?.complemento?.recurso.nombre}}</span>
              </mat-card-title>
              <mat-card-subtitle>
                <strong>#{{orden?.idExterno}}</strong>
              </mat-card-subtitle>
              <div class="state">
                <span i18n="StateLabelOrder | ordenes de servicio">State: </span>
                <strong>{{orden?.estado?.nombre}}</strong>
              </div>
            </div>
          </mat-card-title-group>

          <mat-card-content>
            <div class="row">
              <div class="col-12">
                <ul role="list">
                  <li role="listitem">
                    <span i18n="fechaServicePage | Fecha">Date: </span>
                    <span>{{orden?.fechaCreado | date: 'd/M/y' }}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_COMPRA_PLAN'">
                    <span i18n="ServiceLabelOrder | ordenes de servicio">Service: </span>
                    <span>{{orden?.plan?.servicio?.nombre}}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_COMPRA_PLAN'">
                    <span i18n="PlanLabelOrder | ordenes de servicio">Plan: </span>
                    <span>{{orden?.plan?.nombre}}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_CANCELACION_PLAN'">
                    <span i18n="ServiceLabelOrder | ordenes de servicio">Service: </span>
                    <span>{{orden?.contrato.plan?.servicio?.nombre}}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_CANCELACION_PLAN'">
                    <span i18n="IdSubscriptionLabelOrder | ordenes de servicio">Subscription ID: </span>
                    <span>{{orden?.contrato?.idSuscripcionExterno}}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_COMPRA_PLAN' && orden?.recursos?.length > 0">
                    <span i18n="ComplementList | ComplementListLabel">Add-ons: </span>
                    <span *ngFor="let recurso of orden?.recursos">{{recurso?.recurso?.nombre}}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_COMPRA_COMPLEMENTO' && orden?.complementos?.length > 0">
                    <span i18n="ComplementList | ComplementListLabel">Add-ons: </span>
                    <span *ngFor="let recurso of orden?.complementos">{{recurso?.recurso?.nombre}}</span>
                  </li>
                  <li role="listitem" *ngIf="orden.tipo === 'ORDEN_CANCELACION_COMPLEMENTO'">
                    <span i18n="ComplementTitle | ComplementListLabel">Add-ons: </span>
                    <span>{{orden?.complemento?.recurso?.nombre}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-list-item>
    </mat-list>
  </div>
</div>
