<div class="header-nav">
  <mat-toolbar>
    <mat-toolbar-row class="user-toolbar">
      <!-- User not Logged -->
      <ng-container *ngIf="!user">
        <div class="user-toolbar__nologged">
          <div fxShow="true" fxHide.lt-md><!-- class="auth-nav" -->
            <button (click)="login()" mat-button>
              <mat-icon color="primary">person</mat-icon>
              <span i18n="ButtonLogin | El boton de login en el header">Login</span>
            </button>
            <!--<button (click)="register()" mat-button>
              <mat-icon color="primary">add_circle</mat-icon>
              <span i18n="ButtonRegister | El boton de Registro en el header">Register</span>
            </button>-->
          </div>
        </div>
      </ng-container>

      <!-- User Logged -->
      <ng-container *ngIf="user">
        <div class="user-toolbar__logged">
          <!-- <div>
            <button mat-button i18n="My athenaeums| Ícono de mis ateneos" (click)="openAthenaeums()">
              <mat-icon color="primary">apps</mat-icon>
              My athenaeums
            </button>
          </div> -->
          <div>
            <button mat-button [matMenuTriggerFor]="menu1">
              <mat-icon color="primary">settings</mat-icon>
              Manage
            </button>
            <mat-menu #menu1="matMenu" class="mat-menu-user">
              <a routerLink="/subscriptions">
                <mat-icon color="primary">shop</mat-icon><span i18n="Suscripciones Icon| Icono del menu del header en el cual se tiene acceso a las suscripciones">Purchases</span></a>
              <a routerLink="ordenes/servicios">
                <mat-icon color="primary">dynamic_feed</mat-icon><span i18n="Ordenes de Servicio Icon| Icono del menu del header en el cual se tiene acceso a las ordenes de servicio">Orders</span></a>
                <a routerLink="invoice">
                  <mat-icon color="primary">receipt</mat-icon><span i18n="Facturas Icon| Icono del menu del header en el cual se tiene acceso a las facturas">Invoices</span></a>
              <a routerLink="payments">
                <mat-icon color="primary">paid</mat-icon><span i18n="Pagos Icon| Icono del menu del header en el cual se tiene acceso a los pagos">Payments</span></a>
            </mat-menu>
          </div>
          <div>
            <button mat-button [matMenuTriggerFor]="menu2">
              <mat-icon color="primary">person</mat-icon>
              {{user.firstName}} {{user.lastName}}
            </button>
            <mat-menu #menu2="matMenu" class="mat-menu-user">
              <a href="user" i18n="UserData | Icono del menu del header en  el cual se tiene acceso a los datos de usuario">
                <mat-icon color="primary">person</mat-icon>
                User data
              </a>
<!--              <a href="#" i18n="ChangePass | Icono del menu del header en  el cual se tiene acceso al cambio de Password">-->
<!--                <mat-icon color="primary">lock</mat-icon>-->
<!--                Change password-->
<!--              </a>-->
              <a (click)="logOut()" i18n="Salir | Icono del menu del header en  el cual se hace Logout">
                <mat-icon color="primary">exit_to_app</mat-icon>
                Logout
              </a>
            </mat-menu>
          </div>
          <div>
            <button mat-button [matMenuTriggerFor]="menu3">
              <mat-icon [matBadge]="badgeContent" matBadgePosition="above after" id="badgeContent" matBadgeColor="primary" matBadgeSize="small">
                notifications
              </mat-icon>
            </button>
            <mat-menu #menu3="matMenu" xPosition="before" class="mat-menu-notification">
              <div class="notification-title" i18n="notifications | notifications">notifications</div>
              <ng-container *ngFor="let notification of notifications" class="notification-container">
                <mat-list role="list" class="list-notifications">
                  <!-- Notificación nueva -->
                  <mat-list-item role="listitem" class="notification-item notification-item--new">
                    <mat-icon mat-list-icon color="primary">{{ notification.tipoId }}</mat-icon>
                    <div matLine>
                      <a href="" [matMenuTriggerFor]="menu3" #matMenu3Trigger="matMenuTrigger" (click)="matMenu3Trigger.closeMenu();openNotification(notification)">
                        {{notification.titulo | limitTo: '25' }}
                      </a>
                    </div>
                    <div class="list-actions">
                      <!-- Marcar como leído -->
                      <ng-template [ngIf]="!notification.leido">
                        <a href="#" mat-icon-button aria-label="Marcar como leída" (click)="readNotificationCheck(notification, true)">
                          <mat-icon>mark_email_unread</mat-icon>
                        </a>
                      </ng-template>
                      <!-- Marcar como no leído -->
                      <ng-template [ngIf]="notification.leido">
                        <a href="#" mat-icon-button aria-label="Marcar como no leída" (click)="readNotificationCheck(notification, false)">
                          <mat-icon>mark_email_read</mat-icon>
                        </a>
                      </ng-template>
                      <!-- Ocultar -->
                      <a href="#" mat-icon-button aria-label="Ocultar notificación" (click)="hideNotificationCheck(notification, true)">
                        <mat-icon>clear</mat-icon>
                      </a>
                    </div>
                  </mat-list-item>
                </mat-list>
              </ng-container>
              <a mat-menu-item href="notifications" i18n="ViewAllNotifications | ViewAllNotifications">View all Notifications</a>
            </mat-menu>
          </div>
        </div>
      </ng-container>

      <div>
        <button mat-button class="language-cta" [matMenuTriggerFor]="language">
          <span *ngIf="IsEnglish"><img src="assets/images/flags/UK.png" alt="English" /></span>
          <span *ngIf="!IsEnglish"><img src="assets/images/flags/ES.png" alt="Español" /></span>
        </button>
        <mat-menu #language="matMenu" class="mat-menu-language">
          <a mat-button mat-icon *ngIf="IsEnglish" (click)="changeLanguage('es')"><img src="assets/images/flags/ES.png" alt="" /> Español</a>
          <a mat-button mat-icon *ngIf="!IsEnglish" (click)="changeLanguage('en')"><img src="assets/images/flags/UK.png" alt="" /> Inglés</a>
        </mat-menu>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="nav-toolbar">
      <div class="d-flex align-items-center">
        <!-- Logo -->
        <a [routerLink]="'/'" class="logo-brand">
          <img alt="Aleph" src="{{logo}}" />
          <span i18n="MARKETPLACE">CATALOG</span>
        </a>
        <!-- Categories -->
        <app-category-list class="categories-list d-none d-lg-block" [categories]="categories"></app-category-list>
      </div>

      <div class="d-flex align-items-center">
        <button mat-button *ngIf="this.searchBoxService.getValue()" (click)="openDialog()">
          <mat-icon color="primary">search</mat-icon>
          <span class="d-none d-md-inline" i18n="SearchBox | Icono del header en el cual se tiene acceso a la busqueda">Search for solutions</span>
        </button>

        <!-- Mobile CTA -->
        <button mat-icon-button (click)="onToggleSidenav()" align='end' class="d-lg-none">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
