import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contact} from '../models/contact.model';
import { environment } from 'src/environments/environment';
import {Notification} from '../models/notification.model';
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpClient: HttpClient,
              private keylcoakService: KeycloakService
  ) {

  }

  public getAll$(limite: number, desplazamiento: number, user: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('limite', limite.toString());
    params = params.append('desplazamiento', desplazamiento.toString());
    params = params.append('borrado', 'false');
    params = params.append('oculto', 'false');

    return this.httpClient.get<any[]>(environment.apiBase + '/aplicaciones/' + environment.keycloakConfig.realm + '/tenants/' + environment.keycloakConfig.clientId + '/notificaciones/usuarioWeb',
     {params}
    );
  }

  public obtenerTodos(path: string): Observable<any> {
    return this.httpClient.get<any>(path);
  }

  public updateNotification(notificationId: number, property : string, status: boolean): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }

    const body = "{\"" + property +"\":"+ status +"}";
    var index = environment.apiBase.indexOf( "/api" );
        let path = environment.apiBase + "/aplicaciones/" + environment.keycloakConfig.realm
        + "/tenants/" + environment.keycloakConfig.clientId
        + "/notificaciones/" + notificationId;
    return this.httpClient.put<any>(path, body, httpOptions);
  }
}
