<footer>
  <div class="footer__links">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul>
            <li>
              <span i18n="ContactInfo | Titulo de info de contacto en el footer">Contact us: </span>
              <a href="mailto:{{email}}" target="_blank">{{email}}</a>
            </li>
            <!-- <li>
              <a href="#" i18n="FAQ'sFooter">FAQs</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  

  <div class="footer__copy">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span  i18n="Copyright" >&copy;Copyright 2022 Quanam. All rights reserved.</span>
          <span  i18n="CopyrightMarks" >All trademarks, logos and brand names are the property of their respective owners.</span>
        </div>
      </div>
    </div>
  </div>
</footer>
