import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Perfil} from '../models/perfil.model';
import {environment} from '../../environments/environment';
import {PlanPerfil} from '../models/planperfil.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private httpClient: HttpClient) {
  }

  getProfilesByProvider(planId: number): Observable<PlanPerfil[]> {
    return this.httpClient.get<PlanPerfil[]>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/planes/' + planId + '/perfiles'
    );
  }


}
