import {AfterViewInit, Component, ViewChild} from '@angular/core';

// begin HTML Demo
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { PaymentService } from 'src/app/services/payment.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@utils';

export interface PaymentsData {
  id: number;
  idReferencia: number;
  idExternoOrden: string;
  tipoReferencia: string;
  fechaPagado: Date;
  fechaCreado: Date;
  pasarelaPago: PasarelaPago;
  estadoPago: string;
  importe: number;
  importeGravado: number;
  codigoAutorizacion: string;
}

export interface PasarelaPago {
  id: number;
  idExterno: string;
  nombre: string;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.page.html',
  styleUrls: [],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},

  ]
})
export class PaymentsPage implements AfterViewInit {

  pageIndex = 0;
  pageSize = 10;
  total: number;
  data:any[]=[];

  // begin HTML Demo
  displayedColumns: string[] = ['paymentNumber', 'reference', 'dateCreated','datePayment', 'status', 'value', 'gateway', 'authorization'];
  dataSource = new MatTableDataSource<PaymentsData>(this.data);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // end HTML Demo

  estado:string=null;
  tipo:string=null;
  rangoDias:string=null;
  fechaInicio:Date=null;
  fechaFin:Date=null;


  constructor(public paymentService:PaymentService) { }

  ngOnInit(): void {
  }

  // begin HTML Demo
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  // end HTML Demo
  buscar() {
    this.paymentService.getAll(this.pageSize , this.pageIndex , this.fechaInicio, this.fechaFin, this.rangoDias, this.estado, this.tipo ).subscribe(resp=>{
      this.data=resp.pagos;
      this.dataSource = new MatTableDataSource(this.data);
      this.total = resp.total;
      console.log(resp);
    });
  }

  public paginar($event: any) {
    this.pageIndex = $event.pageIndex * $event.pageSize;
    this.pageSize = $event.pageSize;
    this.buscar();
  }

}
