import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private httpClient: HttpClient) {
  }

  public getAll(limite: number, desplazamiento: number, fechaInicio: string, fechafin: string, rangoDias: string, estado: string): Observable<any> {
    let params = new HttpParams();
    if (rangoDias == 'RANGO' || rangoDias == 'NINGUNO') {
      rangoDias = null;
    }
    if (estado == 'NINGUNO') {
      estado = null;
    }
    if (rangoDias != null) {
      params = params.append('rangoDias', rangoDias);
    }
    if (fechaInicio != null) {

      params = params.append('fechaInicio', fechaInicio);
    }
    if (fechafin != null) {
      params = params.append('fechaFin', fechafin);
    }

    if (estado != null) {
      params = params.append('estado', estado);
    }

    if (desplazamiento != null) {
      params = params.append('desplazamiento', desplazamiento.toString());
    }
    if (limite != null) {
      params = params.append('limite', limite.toString());
    }


    return this.httpClient.get<any>(environment.apiBase + '/tiendas/' + environment.idStore + '/facturasPaginable/',
//  return this.httpClient.get<any>("http://localhost:8080/api/tiendas/"+ environment.idStore + "/facturasPaginable/",
      {params}
    );
  }

  pagarFactura(idFactura: number): Observable<any> {
    const factura = {idFactura: idFactura};
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/facturas/' + idFactura + '/pagos',
      factura, {responseType: 'text'}
    );
  }


}
