import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '@models';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html'
  //styleUrls: ['./product-list-item.component.scss']
})
export class ProductListItemComponent {

  constructor(private router: Router,
              private route: ActivatedRoute){}
  @Input()
  product: Product;
  @Input()
  busqueda = false;

  redirect() {
    if (this.busqueda) {
      this.router.navigate(['product/' + this.product.idExterno]);
    } else {
      this.router.navigate(['product/' + this.product.idExterno], {relativeTo: this.route});
    }

  }
}
