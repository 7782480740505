import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SearchDialogComponent} from '../components/search-dialog/search-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) {

  }

  open(){
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.height = '100%';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    this.dialog.open(SearchDialogComponent, dialogConfig);
  }
  close(){
    this.dialog.closeAll();
  }
}
