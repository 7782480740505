<h2 mat-dialog-title class="mat-typography__underscore" i18n="ModifyUserDataTitle | ModifyUserDataTitle">Modify user data</h2>
<mat-dialog-content>
  <p i18n="ModifyUserDataDescription | ModifyUserDataDescription" >You are about to modify user data.</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button color="accent" (click)="onCancel()" i18n="CancelModifyUserData | CancelModifyUserData" >Cancel</button>
  <button mat-flat-button color="primary" cdkFocusInitial (click)="confirmUpdateUser(userForm)">
    <span i18n="AcceptModifyUserData | AcceptModifyUserData">Accept</span>
    <mat-icon *ngIf="busy">
      <mat-spinner color="accent" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
</mat-dialog-actions>


