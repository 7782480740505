import { trigger, state, style, transition, animate, group, query, stagger, keyframes} from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
      state('inactive', style({
          'max-height': '0',
      })),
      state('active', style({
          'max-height': '100vh',
      })),
      transition('inactive => active', animate('350ms ease-in')),
      transition('active => inactive', animate('350ms ease-out'))
  ])
]
