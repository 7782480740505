<div class="addons" *ngIf="recursosParsed?.length  > 0">
  <form [formGroup]="recursosFormGroup" class="form form--horizontal">
    <ng-container *ngFor="let recursos of recursosParsed">

      <div *ngIf="recursos[0]?.tipo === 'LISTA' && (recursos[0]?.recurso.agendable == null || recursos[0]?.recurso.agendable === 'NO_AGENDABLE')" class="row">
        <div class="col-12 mt-3">
          <mat-form-field appearance="fill">
            <mat-label>
              {{recursos[0]?.listaNombre}}
              <ng-container *ngIf="isRequired(recursos) && !this.compraComplemento"> *</ng-container>
            </mat-label>
            <mat-select formControlName="{{recursos[0]?.recurso?.id}}" (change)="selectOption($event.target.value,recursos[0].recurso.id)">
              <mat-option *ngFor="let recurso of recursos" [value]="recurso?.recurso?.id.toString()">{{recurso?.recurso?.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="recursos[0]?.tipo === 'LISTA' && (recursos[0]?.recurso.agendable != null && recursos[0]?.recurso.agendable != 'NO_AGENDABLE') " class="row">
        <div class="col-12 mt-3">
          <mat-card class="grey-card mat-elevation-z0 mt-4">
            <mat-card-content class="text-center">
              <p class="mb-3" i18n="AgendarListInfoButton | Info boton agendar Recursos Lista">Click to schedule {{recursos[0]?.listaNombre}}</p>
              <button mat-flat-button color="accent" (click)="openDialogScheduleComplement(recursos)" i18n="AgendarListButton | Boton agendar Recursos Lista">SCHEDULE</button>
            </mat-card-content>
          </mat-card>
          <ng-container *ngIf="recursosFormGroup.controls[recursos[0]?.listaNombre+'-nombre'].value">
            <mat-form-field appearance="none">
              <input matInput formControlName="{{recursos[0]?.listaNombre}}-nombre" readonly name="{{recursos[0]?.listaNombre}}-nombre" ngDefaultControl> <!-- id="input1" -->
            </mat-form-field>

            <mat-form-field appearance="none">
              <mat-label i18n="LabelListDateFrom | Label AgendaDesde Agendable Lista">From:</mat-label>
              <input matInput formControlName="{{recursos[0]?.listaNombre}}-fechaDesde" readonly name="{{recursos[0]?.listaNombre}}-fechaDesde" ngDefaultControl> <!-- id="input1" -->
            </mat-form-field>

            <mat-form-field appearance="none">
              <mat-label i18n="LabelListDateTo | Label AgendaHasta Agendable Lista">To:</mat-label>
              <input matInput formControlName="{{recursos[0]?.listaNombre}}-fechaHasta" readonly name="{{recursos[0]?.listaNombre}}-fechaHasta" ngDefaultControl> <!-- id="input1" -->
            </mat-form-field>
            <mat-form-field appearance="none">
              <mat-label i18n="LabelListQuantity | Label Cantidad Agendable Lista">Quantity:</mat-label>
              <input matInput type="number" formControlName="{{recursos[0]?.listaNombre}}" readonly name="{{recursos[0]?.listaNombre}}" ngDefaultControl> <!-- id="input1" -->
              <span matSuffix>{{recursos[0]?.unidad}}</span>
            </mat-form-field>
          </ng-container>

        </div>

      </div>

      <div *ngIf="recursos[0]?.tipo === 'ELEMENTO' && (recursos[0]?.recurso.agendable == null || recursos[0]?.recurso.agendable === 'NO_AGENDABLE')" class="row">
        <div class="col-sm-8 mt-3">
          <mat-label>
            {{recursos[0]?.recurso?.nombre}}
            <ng-container *ngIf="isRequired(recursos) && !this.compraComplemento"> *</ng-container>
          </mat-label>
        </div>
        <div class="col-sm-4 mt-3">
          <mat-form-field appearance="fill" class="form-label-outside">
            <input matInput type="number" formControlName="{{recursos[0]?.recurso?.id}}"> <!-- id="input1" -->
            <span matSuffix>{{recursos[0]?.unidad}}</span>
          </mat-form-field>
          <!--
          <div class="input-group">
            <input formControlName="{{recursos[0]?.recurso?.id}}" type="number" class="form-control" id="input1">
            <div class="input-group-append">
              <div class="input-group-text">{{recursos[0]?.unidad}}</div>
            </div>
          </div>
          -->
        </div>
      </div>

      <div *ngIf="recursos[0]?.tipo === 'ELEMENTO' && (recursos[0]?.recurso.agendable != null && recursos[0]?.recurso.agendable != 'NO_AGENDABLE')" class="row">
        <div class="col-12 mt-3">
          <mat-card class="grey-card mat-elevation-z0 mt-4">
            <mat-card-content class="text-center">
              <p class="mb-3" i18n="AgendarElementInfoButton | Info boton agendar Recursos Elemento">Click to schedule {{recursos[0]?.recurso?.nombre}}</p>
              <button mat-flat-button color="accent" (click)="openDialogScheduleComplement(recursos)" i18n="AgendarElementButton | Boton agendar Recursos Elemento">SCHEDULE</button>
            </mat-card-content>
          </mat-card>

          <ng-container *ngIf="recursosFormGroup.controls[recursos[0]?.recurso.id+'-nombre'].value">
            <mat-form-field appearance="none">
              <input matInput formControlName="{{recursos[0]?.recurso?.id}}-nombre" readonly name="{{recursos[0]?.recurso?.id}}-nombre" ngDefaultControl> <!-- id="input1" -->
            </mat-form-field>

            <mat-form-field appearance="none">
              <mat-label i18n="LabelElementDateFrom | Label AgendaDesde Agendable Elemento">From:</mat-label>
              <input matInput formControlName="{{recursos[0]?.recurso?.id}}-fechaDesde" readonly name="{{recursos[0]?.recurso?.id}}-fechaDesde" ngDefaultControl> <!-- id="input1" -->
            </mat-form-field>

            <mat-form-field appearance="none">
              <mat-label i18n="LabelElementDateTo | Label AgendaHasta Agendable Elemento">To:</mat-label>
              <input matInput formControlName="{{recursos[0]?.recurso?.id}}-fechaHasta" readonly name="{{recursos[0]?.recurso?.id}}-fechaHasta" ngDefaultControl> <!-- id="input1" -->
            </mat-form-field>

            <mat-form-field appearance="none">
              <mat-label i18n="LabelElementQuantity | Label Cantidad Agendable Elemento">Quantity:</mat-label>
              <input matInput type="number" formControlName="{{recursos[0]?.recurso?.id}}" readonly name="{{recursos[0]?.recurso?.id}}" ngDefaultControl> <!-- id="input1" -->
              <span matSuffix>{{recursos[0]?.unidad}}</span>
            </mat-form-field>
          </ng-container>
        </div>
      </div>

      <div *ngIf="recursos[0]?.tipo === 'LISTA_RADIO_BUTTON'" class="row">
        <div class="col-12 mt-4">
          <mat-label class="d-block mb-3">
            {{recursos[0]?.listaNombre}}
            <ng-container *ngIf="isRequired(recursos) && !this.compraComplemento"> *</ng-container>
          </mat-label>

          <mat-radio-group aria-label="Select an option">
            <mat-radio-button *ngFor="let recurso of recursos" formControlName="{{recursos[0]?.recurso?.id}}"
                              [value]="recursos[0]?.recurso?.id.toString()" [checked]="false" class="mr-3" name="{{recursos[0]?.recurso?.id}}" ngDefaultControl>
              {{recurso?.recurso?.nombre}}
            </mat-radio-button>
          </mat-radio-group>
          <!-- <div class="form-check" *ngFor="let recurso of recursos">
            <input formControlName="{{recursos[0]?.recurso?.id}}" class="form-check-input" type="radio" name="{{recursos[0]?.recurso?.id}}" id="exampleRadios1" [value]="recurso?.recurso?.id.toString()" checked>
            <label class="form-check-label" for="exampleRadios1">
              {{recurso?.recurso?.nombre}}
            </label>
          </div> -->
        </div>
      </div>

      <div *ngIf="recursos[0]?.tipo === 'CHECKBOX'" class="row">
        <div class="col-12 mt-4">
          <mat-label>
            {{recursos[0]?.listaNombre}}
            <ng-container *ngIf="isRequired(recursos) && !this.compraComplemento"> *</ng-container>
          </mat-label>
          <div *ngFor="let recurso of recursos">
            <mat-checkbox formControlName="{{recursos[0]?.recurso?.id}}" [checked]="false">
              {{ recurso?.recurso?.nombre}}
            </mat-checkbox>
            <!-- <div class="form-check" *ngFor="let recurso of recursos">
              <input name="{{recursos[0]?.recurso?.id}}" formControlName="{{recursos[0]?.recurso?.id}}" class="form-check-input" type="checkbox" [checked]="false">
              <label class="form-check-label" for="examplecheckbox1">
                {{ recurso?.recurso?.nombre}}
              </label>
            </div> -->
          </div>
        </div>
      </div>

      <div *ngIf="this.recursosFormGroup.get(recursos[0].recurso.id.toString())?.invalid && (this.recursosFormGroup.get(recursos[0].recurso.id.toString())?.dirty || this.recursosFormGroup.get(recursos[0].recurso.id.toString())?.touched || this.showErrors)">
        <mat-error *ngIf="this.recursosFormGroup.get(recursos[0].recurso.id.toString())?.errors?.required">
          <ng-container *ngIf="recursos[0]?.tipo == 'LISTA' || recursos[0]?.tipo == 'LISTA_RADIO_BUTTON'" i18n="RequiredMessageListComplements | Mensaje de requerido en complementos para lista de complementos">
            {{recursos[0]?.listaNombre }} is required.
          </ng-container>
          <ng-container *ngIf="recursos[0]?.tipo == 'ELEMENTO' || recursos[0]?.tipo == 'CHECKBOX'" i18n="RequiredMessageResourceComplements | Mensaje de requerido en complementos para recurso">
            {{recursos[0]?.recurso?.nombre }} is required.
          </ng-container>
        </mat-error>
        <mat-error *ngIf="this.recursosFormGroup.get(recursos[0].recurso.id.toString())?.errors?.max" i18n="MaxMessageResourceComplements | Mensaje de valor maximo en recurso">
          The max value is {{recursos[0]?.maximo }}
        </mat-error>
        <mat-error *ngIf="this.recursosFormGroup.get(recursos[0].recurso.id.toString())?.errors?.min" i18n="MinMessageResourceComplements | Mensaje de valor minimo en recurso">
          The min value is {{recursos[0]?.minimo }}
        </mat-error>
      </div>
    </ng-container>
  </form>
</div>
