<div class="container">
  <div class="row justify-content-center pb-5">
    <div class="col-8 text-justify">
      <h1 class="pt-5">DISCLAIMER</h1>
      <h3>WEBSITE DISCLAIMER</h3>
      <p>
        The information provided by Mia Wellness and Healthcare International LLC ("we," "us," or "our") on
        https://www.mytreat.health (the "Site") is for general informational purposes
        only. All information on the Site is provided in good faith, however we make no
        representation or warranty of any kind, express or implied, regarding the accuracy,
        adequacy, validity, reliability, availability, or completeness of any information on
        the Site.
      </p>
      <p>
        UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU
        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
        THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED
        ON THE SITE. YOUR USEOF THE SITE AND YOUR RELIANCE ON ANY
        INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
      </p>
      <p>
        TESTIMONIALS DISCLAIMER
        The Site may contain testimonials by users of our products and/or services. These
        testimonials reflect the real-life experiences and opinions of such users. However,
        the experiences are personal to those particular users, and may not necessarily be
        representative of all users of our products and/or services. We do not claim, and you
        should not assume, that all users will have the same experiences. YOUR
        INDIVIDUAL RESULTS MAY VARY.
        The testimonials on the Site are submitted in various forms such as text, audio and/or
        video, and are reviewed by us before being posted. They appear on the Site verbatim
        as given by the users, except for the correction of grammar or typing errors. Some
        testimonials may have been shortened for the sake of brevity where the full
        testimonial contained extraneous information not relevant to the general public. The
        views and opinions contained in the testimonials belong solely to the individual user
        and do not reflect our views and opinions. We are not affiliated with users who
        provide testimonials, and users are not paid or otherwise compensated for their
        testimonials. The testimonials on the Site are not intended, nor should they be
        construed, as claims that our products and/or services can be used to diagnose, treat,
        mitigate, cure, prevent, or otherwise be used for any disease or medical condition.
        No testimonials have been clinically proven or evaluated. </p>
    </div>
  </div>
</div>