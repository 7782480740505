import {Title} from '@angular/platform-browser';
import {ConstantsUtils} from '@utils';

export abstract class AbstractPage {

  public titleService = ConstantsUtils.injector.get(Title);

  public abstract pageTitle(): string;

  constructor() {
    this.titleService.setTitle(this.pageTitle());
  }

}
