<mat-card class="product-card mat-elevation-z0"><!-- example-card -->
  <img mat-card-image src="{{product.imgUrl}}" (click)="redirect()"/>
  <mat-card-content>
    <!--    <div class="mat-card-category" i18n="NameOftheProducter">Producer</div>-->
    <h2 class="mat-card-title" (click)="redirect()">{{product.nombre}}</h2>
    <div (click)="redirect()" class="mat-card-description" [innerHTML]="product.descripcion"></div>

    <div class="tags">
      <ng-container *ngIf="product.categorias?.length <= 5">
        <a [routerLink]="'/'+category.fullIdExterno" *ngFor="let category of product.categorias" href="" mat-button>
          <i class="material-icons">arrow_right</i>
          {{category.nombre}}
        </a>
      </ng-container>
      <ng-container *ngIf="product.categorias?.length > 5">
        <a [routerLink]="'/'+category.fullIdExterno" *ngFor="let category of product.categorias.slice(0,5)" href="" mat-button>
          <i class="material-icons">arrow_right</i>
          {{category.nombre}}
        </a>
      </ng-container>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <ng-container *ngIf="product.precioDesde && product.precioDesde != '0'">
      <div class="mat-card-price">
        <small i18n="FromTag | Etiqueta desde sobre el precio Desde del producto">From</small>
        <span *ngIf="product.precioDesde.toString().substring(0,3).match('[a-zA-z][a-zA-z][a-zA-z]') != null">
          {{product.precioDesde.toString().substring(0, 3)}} $<span class="mat-card-price__value">{{product.precioDesde.toString().substring(4)}}</span>
        </span>
        <span *ngIf="product.precioDesde.toString().substring(0,3).match('[a-zA-z][a-zA-z][a-zA-z]') == null">
          $<span class="mat-card-price__value">{{product.precioDesde.toString()}}</span>
        </span>
      </div>
      <button mat-flat-button color="primary" class="mat-button-xs--block" (click)="redirect()" i18n="BuyButton | Boton de comprar de item Producto">Buy</button>
    </ng-container>
    <button *ngIf="!product.precioDesde || product.precioDesde == '0'" mat-flat-button color="primary" class="mat-button-xs--block" (click)="redirect()" i18n="ImInterestedButton | Boton me intresa item Producto">I'm interested</button>
  </mat-card-actions>
</mat-card>
