import {TruncateTextPipe} from './utils/TruncateTextPipe.pipe';
import {NotificationsPage} from './pages/notifications/notifications.page';
import {NotificationDialog} from './layouts/header/header.component';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {HomePage} from './pages/home/home-page';
import {ProductPage} from './pages/product/product-page';
import {CategoryListComponent} from './components/category-list/category-list.component';
import {ProductListComponent} from './components/product-list/product-list.component';
import {CategorySliderComponent} from './components/category-slider/category-slider.component';
import {ProductSliderComponent} from './components/product-slider/product-slider.component';
import {ProductListItemComponent} from './components/product-list/product-list-item/product-list-item.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {DashboardPage} from './pages/dashboard/dashboard.page';
import {CategoryPage} from './pages/category/category.page';
import {FooterComponent, HeaderComponent, NormalLayoutComponent} from '@layouts';
import {MatDialogModule} from '@angular/material/dialog';
import {SearchDialogComponent} from './components/search-dialog/search-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ConstantsUtils, MY_FORMATS} from '@utils';
import {initializer} from './app-init';
import {MatExpansionModule} from '@angular/material/expansion';
import {SearchPage} from './pages/search/search-page';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {OrderMessageDialog, PurchasePage} from './pages/purchase/purchase-page';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SubscriptionsPage} from './pages/subscriptions/subscriptions.page';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ServicesPage} from './pages/orders/services/services.page';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PaymentPage} from './pages/user/payment/payment.page';
import {ConfirmSubDialog, UserPage} from './pages/user/user.page';
import {SafePipe} from './utils/htmlParse';
import {
  ComplementsListComponent,
  ScheduleableComplementDialog
} from './components/complements-list/complements-list.component';
import {MatBadgeModule} from '@angular/material/badge';
import {
  AddUserDialog,
  CancelComplementDialog,
  CancelSubscriptionDialog,
  DeleteUserDialog,
  DetailPage,
  PurchaseComponentDialog
} from './pages/subscriptions/detail/detail.page';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ResponseInterceptor} from './shared-module/interceptors/ResponseInterceptor';
import {SwiperModule} from 'swiper/angular';
import {ProviderPage} from './pages/provider/provider-page';

import {DisclaimerPage} from './pages/static/disclaimer/disclaimer-page';
import {PrivacyPage} from './pages/static/privacy/privacy-page';
import {TermsPage} from './pages/static/terms/terms-page';


// HTML Demo
import {InvoicePage} from './pages/invoice/invoice.page';
import {PaymentsPage} from './pages/payments/payments.page';
import {AcceptModificationsComponent} from './pages/purchase/accept-modifications/accept-modifications.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {BannerComponent} from './components/product-list/product-list-item/banner/banner.component';
import {BannerTituloComponent} from './components/product-list/product-list-item/banner-titulo/banner-titulo.component';
import {
  BannerCarruselComponent
} from './components/product-list/product-list-item/banner-carrusel/banner-carrusel.component';
import {BannerImagenComponent} from './components/product-list/product-list-item/banner-imagen/banner-imagen.component';
import {BannerVideoComponent} from './components/product-list/product-list-item/banner-video/banner-video.component';
import {
  BannerAplicacionComponent
} from './components/product-list/product-list-item/banner-aplicacion/banner-aplicacion.component';
import {
  BannerPersonalizadoComponent
} from './components/product-list/product-list-item/banner-personalizado/banner-personalizado.component';
import {AppComponent} from './app.component';
import localeESUY from '@angular/common/locales/es-UY';
import {LangService} from './services/lang.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {environment} from "../environments/environment";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {NavigationEnd, Router} from "@angular/router";

registerLocaleData(localeESUY);

function resolveLocale() {
  const defaultValue = 'es-UY';
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return defaultValue;
  }
  const wn = window.navigator as any;
  // let lang = wn.languages ? wn.languages[0] : defaultValue;
  // lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
  const langService = new LangService();
  const lang = langService.getLang();
  let finalLang = '';
  if (lang.startsWith('es')) {
    finalLang = 'es-UY';
  } else {
    finalLang = 'en-US';
  }
  return finalLang;
}


// const keycloakService: KeycloakService = new KeycloakService();

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    CategoryPage,
    ProductPage,
    CategoryListComponent,
    ProductListComponent,
    CategorySliderComponent,
    ProductSliderComponent,
    ProductListItemComponent,
    HeaderComponent,
    FooterComponent,
    NormalLayoutComponent,
    DashboardPage,
    DetailPage,
    SearchDialogComponent,
    SearchPage,
    PurchasePage,
    SubscriptionsPage,
    ServicesPage,
    PaymentPage,
    UserPage,
    NotificationsPage,
    SafePipe,
    OrderMessageDialog,
    ComplementsListComponent,
    PurchaseComponentDialog,
    CancelComplementDialog,
    CancelSubscriptionDialog,
    ConfirmSubDialog,
    NotificationDialog,
    TruncateTextPipe,
    ScheduleableComplementDialog,
    // HTML Demo
    DeleteUserDialog,
    AddUserDialog,
    InvoicePage,
    PaymentsPage,
    AcceptModificationsComponent,
    BannerComponent,
    BannerTituloComponent,
    BannerCarruselComponent,
    BannerImagenComponent,
    BannerVideoComponent,
    BannerAplicacionComponent,
    BannerPersonalizadoComponent,
    ProviderPage,
    DisclaimerPage,
    PrivacyPage,
    TermsPage
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    NgbModule,
    MatCardModule,
    MatTabsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SwiperModule
  ],
  providers: [
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: resolveLocale()},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},

    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {provide: 'googleTagManagerId', useValue: environment.googleTagManagerId},
    DatePipe,
    {provide: LOCALE_ID, useValue: resolveLocale()},

    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],

  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(injector: Injector, private gtmService: GoogleTagManagerService, private router: Router) {
    ConstantsUtils.injector = injector;

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

}

