<!-- <div class="">
  <div class="breadcrumb">
    <div class="container">
      <a [routerLink]="'/'">Inicio</a>
      <ng-container *ngFor="let category$ of this.breadscrum">
        <ng-container *ngIf="category$ | async as category">
          <span> / </span>
          <a [routerLink]="'/'+category.fullIdExterno">{{category.nombre}}</a>
        </ng-container>
      </ng-container>
      <span> / </span>
      <a *ngIf="product">{{product.nombre}}</a>
      <span> / </span>
      <span>Buy a plan</span>
    </div>
  </div>
</div> -->

<!-- <ng-container *ngIf="product">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="mat-typography__underscore mt-5 mb-3">{{product.nombre}}</h1>
      </div>
    </div>
  </div>
</ng-container> -->

<div class="purchaseContent mat-step">
  <div class="container">
    <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
      <mat-step [editable]="this.editable">
        <ng-template matStepLabel i18n="Select Plan| La etiqueta de seleccionar plan">Select Plan</ng-template>
        <div class="row">
          <div class="col-lg-7">
            <div class="mat-step__wrapper">
              <!-- <h2 class="mat-typography__underscore mb-4" i18n="Select Plan| La etiqueta de seleccionar plan">Select plan</h2> -->
              <mat-card class="white-card mat-elevation-z0">
                <!-- <mat-card-title i18n="SelectPlanTitle| titulo de pagina de compra">Select a plan</mat-card-title> -->
                <mat-card-content>
                  <div class="row align-items-center justify-content-between">
                    <div class="col-md-12">
                      <form [formGroup]="planFormGroup" class="form">
                        <mat-form-field appearance="fill">
                          <!--                          <mat-label i18n="Select Plan| El título de seleccionar el plan en el carrito">Select Plan</mat-label>-->
                          <mat-select [compareWith]="compare" formControlName="plan" id="planInput" class="mat-select--lg">
                            <mat-option *ngFor="let plan of product?.planes" [value]="plan.id">{{plan.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </form>
                    </div>
                    <div class="col-md-12">
                      <div [innerHTML]="(this.plan$ | async).infoGeneralHtml | safeHtml:'html'"></div>
                    </div>
                    <!--                    <div class="col-md-4 mt-4 mt-lg-0">-->
                    <!--                      <div class="values">-->
                    <!--                        <ng-container *ngFor="let precio of this.plan?.precios">-->
                    <!--                          <div class="values__plan" *ngIf="precio?.tipo == 'MENSUAL'">-->
                    <!--                            <span>{{precio?.moneda?.simbolo}}</span>{{precio?.precio}}-->
                    <!--                            <span class="iva" i18n="Tax| La etiqueta del impuesto">+Tax</span>-->
                    <!--                          </div>-->
                    <!--                          <div class="values__installation" *ngIf="precio?.tipo == 'INSTALACION'">-->
                    <!--                            <span><small i18n="Installation cost from| La etiqueta del costo de instalación">Installation cost from:</small><br> <span>{{precio.moneda.codigo}}</span> {{precio.precio}}</span>-->
                    <!--                          </div>-->
                    <!--                        </ng-container>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>

<!--                  <div class="row">-->
<!--                    <div class="col-md-8 mt-4">-->
<!--                      <div class="mat-step__description" *ngIf="this.plan?.descripcion != undefined" [innerHTML]="this.plan?.descripcion | safeHtml:'html'"></div>-->
<!--                    </div>-->
<!--                  </div>-->
                </mat-card-content>
              </mat-card>

              <div *ngIf="this.plan?.recursos?.length > 0">
                <mat-card class="grey-card mat-elevation-z0 mt-4">
                  <mat-card-content>
                    <app-complements-list (formCambiado)="calculatePrice2()" [plan$]="this.plan$" [pathValue$]="pathValueResouces$"></app-complements-list>
                  </mat-card-content>
                </mat-card>
              </div>
              <!--              <mat-card class="grey-card mat-elevation-z0 mt-4">-->
              <!--                <mat-card-content class="text-center">-->
              <!--                  <p class="mb-3" i18n="UpgradePriceButtonExplanationComponent | Descripcion de boton actualizar Precio">After entering or updating add-ons click on this button.</p>-->
              <!--                  <button mat-flat-button color="accent" [disabled]="this.planFormGroup?.value?.plan === null" (click)="calculatePrice()" i18n="UpgradePriceButton | Boton actualizar precio">Update price</button>-->
              <!--                </mat-card-content>-->
              <!--              </mat-card>-->
            </div>
          </div>
          <!-- Sidebar -->
          <aside class="col-lg-5 mt-4 mt-lg-0 mat-step__aside">
            <mat-card *ngIf="this.appraisal != undefined" class="grey-card summary-card mat-elevation-z0">
              <mat-card-content class="summary">
                <h2 class="mat-typography__underscore mb-4" i18n="SummaryTitle | Tile summary">Summary</h2>
                <!-- <mat-card-title i18n="SummaryTitle | Tile summary">Summary</mat-card-title> -->
                <div class="summary__item">
                  <span class="summary__category">Plan</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombrePlan != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <strong>{{linea.nombrePlan}}</strong>
                      </span>
                      <span class="summary__value">
                        <span *ngIf="linea.importeBruto>0"><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span *ngIf="linea.importeBruto>0"><strong>{{linea.importeBruto}}</strong></span>
                        <span i18n="SincostoPaln | Sin costo" *ngIf="linea.importeBruto==0"><strong>Without cost</strong></span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="summary__item" *ngIf="this.recursos.length > 0">
                  <span i18n="AddonTitle | titulo de complementos en el resumen de orden" class="summary__category">Add-ons</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombreRecurso != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'"><span class="summary__count">{{linea.cantidad}}x</span></ng-container>
                        {{linea.nombreRecurso}}
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'">*</ng-container>
                      </span>
                      <span class="summary__value">
                        <span>{{this.appraisal.moneda.codigo}}</span>
                        <span>{{linea.importeBruto}}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="info my-4" *ngIf="this.OnetimeCost" i18n="OneTimeCostDisclaimer | costos unicos aclaracion">* One-time costs</div>
                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name">Subtotal</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeBruto}}</span>
                    </span>
                  </div>
                </div>

                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name" i18n="TaxesSummary | Titulo impuestos en el resumen de orden">Taxes</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeImpuestos}}</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item summary__item--highlighted">
                  <div class="summary__item-row">
                    <span class="summary__name">
                      <strong i18n="PurchaseTotalComplements | Titulo Total mensual">Purchase Total</strong>
                    </span>
                    <span class="summary__value">
                      <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                      <span><strong>{{this.appraisal.importeTotal}}</strong></span>
                    </span>
                  </div>
                </div>
                <div *ngIf="appraisal.importeAnticipado > 0">
                  <hr/>
                  <div class="summary__item summary__item--highlighted">
                    <div class="summary__item-row">
                      <span class="summary__name">
                        <strong i18n="PrepaidTitlePurchaseService | Titulo pago anticipado">Amount to pay now</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{this.appraisal.importeAnticipado}}</strong></span>
                      </span>
                    </div>
                  </div>
                  <p class="small mt-3" i18n="DescriptionPrepaidService | descripcion de pago anticipado servicio">
                    You must pay this amount online to proceed with your purchase
                  </p>
                </div>
              </mat-card-content>
            </mat-card>
          </aside>
        </div>
        <div class="mat-step__footer">
          <button mat-flat-button color="white" [routerLink]="'/product/' + product?.idExterno" i18n="CancelButton | Buton de cancelacion de Orden">Cancel</button>
          <button mat-flat-button color="primary" class="ml-4" (click)="validatePlanResourceAndNext()" i18n="ContinueButton | Buton continuar con la orden">Continue</button>
        </div>
      </mat-step>
      <mat-step [editable]="this.editable">
        <ng-template matStepLabel i18n="Billing Detail| La etiqueta de detalle de facturacion">Select Account</ng-template>
        <div class="row">
          <div class="col-lg-7">
            <div class="mat-step__wrapper">
              <h2 class="mat-typography__underscore mb-5" i18n="DetallesDeFacturacion | Titulo del paso de facturacion">Select Account</h2>
              <div class="d-md-flex justify-content-md-start mb-4">
                <button class="mat-button-sm--block" (click)="nuevoClienteContactoPersona()" mat-stroked-button color="accent" i18n="NuevaPersona">Add New Person</button>
                <button class="mat-button-sm--block mt-3 mt-md-0 ml-md-3" (click)="nuevoClienteContactoEmpresa()" mat-stroked-button color="accent" i18n="NuevaEmpresa">Add New Company</button>
              </div>
              <div class="grid grid--account">
                <mat-card class="account-card mat-elevation-z0" *ngFor="let client of clientesContactos">
                  <mat-card-title>{{client?.cliente?.nombre}}</mat-card-title>
                  <mat-card-content class="account">
                    <div class="account__type">{{client.tipo}}</div>
                    <div class="account__name">
                      <span *ngIf="client?.contacto?.esPersona">{{client.contacto.nombre}} {{client.contacto.apellido}}</span>
                      <span *ngIf="client?.contacto?.esEmpresa">{{client.contacto.razonSocial}} ({{client.contacto.nombreComercial}})</span>
                    </div>
                    <div class="account__document">
                      <span *ngIf="esPersona">{{client?.contacto?.tipoDocumento}}</span>
                      <span *ngIf="esPersona&&client?.contacto?.paisDocumento">{{client?.contacto?.paisDocumento?.nombre}}</span>
                      <span *ngIf="esPersona">{{client?.contacto?.numeroDocumento}}</span>
                    </div>

                    <ul class="account__moreinfo">
                      <li *ngIf="client?.contacto?.email != undefined">
                        <mat-icon>email</mat-icon>
                        {{client?.contacto?.email}}</li>
                      <li *ngIf="client?.contacto?.telefono != undefined">
                        <mat-icon>phone</mat-icon>
                        {{client?.contacto?.telefono}}</li>
                      <li *ngIf="client?.contacto?.domicilio != undefined">
                        <mat-icon>home</mat-icon>
                        {{client?.contacto?.domicilio}}</li>
                      <li *ngIf="client?.contacto?.codigoPostal != undefined || client?.contacto?.ciudad != undefined || client?.contacto?.departamento != undefined">
                        <mat-icon>location_city</mat-icon>
                        <span *ngIf="client?.contacto?.codigoPostal != undefined">({{client?.contacto?.codigoPostal}})&nbsp;</span>
                        <span>{{client?.contacto?.ciudad}}</span>
                        <span *ngIf="client?.contacto?.departamento != undefined">, {{client?.contacto?.departamento}}</span>
                      </li>
                    </ul>
                  </mat-card-content>
                  <mat-card-actions>
                    <button mat-flat-button color="primary" (click)="cargarClienteContacto(client)" i18n="ClientAccountButton | Boton Usar esta cuenta en cliente">
                      Use this account
                    </button>
                  </mat-card-actions>
                </mat-card>
              </div>
            </div>
          </div>
          <!-- Sidebar -->
          <aside class="col-lg-5 mt-4 mt-lg-0 mat-step__aside">
            <mat-card *ngIf="this.appraisal != undefined" class="grey-card summary-card mat-elevation-z0">
              <mat-card-content class="summary">
                <h2 class="mat-typography__underscore mb-4" i18n="SummaryTitle | Tile summary">Summary</h2>
                <!-- <mat-card-title i18n="SummaryTitle | Tile summary">Summary</mat-card-title> -->
                <div class="summary__item">
                  <span class="summary__category">Plan</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombrePlan != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <strong>{{linea.nombrePlan}}</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{linea.importeBruto}}</strong></span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="summary__item" *ngIf="this.recursos.length > 0">
                  <span i18n="AddonTitle | titulo de complementos en el resumen de orden" class="summary__category">Add-ons</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombreRecurso != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'"><span class="summary__count">{{linea.cantidad}}x</span></ng-container>
                        {{linea.nombreRecurso}}
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'">*</ng-container>
                      </span>
                      <span class="summary__value">
                        <span>{{this.appraisal.moneda.codigo}}</span>
                        <span>{{linea.importeBruto}}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="info my-4" *ngIf="this.OnetimeCost" i18n="OneTimeCostDisclaimer | costos unicos aclaracion">* One-time costs</div>
                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name">Subtotal</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeBruto}}</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name" i18n="TaxesSummary | Titulo impuestos en el resumen de orden">Taxes</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeImpuestos}}</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item summary__item--highlighted">
                  <div class="summary__item-row">
                    <span class="summary__name">
                      <strong i18n="PurchaseTotalComplements | Titulo Total mensual">Purchase Total</strong>
                    </span>
                    <span class="summary__value">
                      <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                      <span><strong>{{this.appraisal.importeTotal}}</strong></span>
                    </span>
                  </div>
                </div>
                <div *ngIf="appraisal.importeAnticipado > 0">
                  <hr/>
                  <div class="summary__item summary__item--highlighted">
                    <div class="summary__item-row">
                      <span class="summary__name">
                        <strong i18n="PrepaidTitlePurchaseService | Titulo pago anticipado">Amount to pay now</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{this.appraisal.importeAnticipado}}</strong></span>
                      </span>
                    </div>
                  </div>
                  <p class="small mt-3" i18n="DescriptionPrepaidService | descripcion de pago anticipado servicio">
                    You must pay this amount online to proceed with your purchase
                  </p>
                </div>
              </mat-card-content>
            </mat-card>
          </aside>
        </div>
        <div class="mat-step__footer">
          <button mat-flat-button color="white" (click)="goToBack()" i18n="BackButton">Back</button>
        </div>
      </mat-step>
      <mat-step [editable]="this.editable">
        <ng-template matStepLabel i18n="Billing Details| La etiqueta de detalle de facturacion">Customer details</ng-template>
        <div class="row">
          <div class="col-lg-7">
            <div class="mat-step__wrapper">
              <h2 class="mat-typography__underscore mb-0" i18n="DetallesDeFacturacion| Titulo del paso de facturacion">Customer details</h2>
              <p class="text-right"><small i18n="RequiredFields">* Required Fields</small></p>
              <form [class.triggerErrorControls]="triggerErrorControls" [formGroup]="clienteFormGroup" class="form">
                <mat-form-field appearance="fill">
                  <mat-label i18n="AliasDeCliente">Billing Alias</mat-label>
                  <input matInput type="text" formControlName="nombre" placeholder="" required>
                </mat-form-field>
              </form>
              <form [class.triggerErrorControls]="triggerErrorControls" [formGroup]="contactoPersonaFormGroup" *ngIf="esPersona" class="form">
                <div class="form-row">
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputFirstName" i18n="NombreCliente">First name</mat-label>
                      <input id="inputFirstName" matInput type="text" formControlName="nombre" placeholder="" required>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputLastName" i18n="Apellido">Last name</mat-label>
                      <input id="inputLastName" matInput type="text" formControlName="apellido" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputType" i18n="Tipo de Documento">Document type</mat-label>
                      <mat-select [compareWith]="equals" formControlName="tipoDocumento" id="inputType">
                        <mat-option [value]="'CI'" i18n="OpcionCIDeLaLista">CI</mat-option>
                        <mat-option [value]="'PASAPORTE'" i18n="OpcionPassaporteDeLaLista">Passport</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputPaisDocumento" i18n="DocumentCountry | Label que pide el Pais del documento">Document country</mat-label>
                      <mat-select formControlName="paisDocumento" id="inputPaisDocumento">
                        <mat-option *ngFor="let pais of paises$ | async" [value]="pais">{{this.lang == 'es' ? pais.nombre : pais.nombreEng}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputNumber" i18n="DocumentNumber">Document number</mat-label>
                      <input id="inputNumber" matInput type="text" formControlName="numeroDocumento" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputAddress" i18n="Address">Address</mat-label>
                      <input matInput type="text" formControlName="domicilio" id="inputAddress" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-2">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputZip" i18n="ZipCode">Zip code</mat-label>
                      <input matInput type="text" formControlName="codigoPostal" id="inputZip" placeholder="">
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputPais" i18n="CountryAddress | Label que pide el Pais Domicilio">Country</mat-label>
                      <mat-select formControlName="paisDomicilio" id="inputPais">
                        <mat-option *ngFor="let pais of paises$ | async" [value]="pais">{{this.lang == 'es' ? pais.nombre : pais.nombreEng}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputState" i18n="State">State</mat-label>
                      <input matInput type="text" formControlName="departamento" id="inputState" placeholder="">
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputCiudad" i18n="City">City</mat-label>
                      <input matInput type="text" formControlName="ciudad" id="inputCiudad" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputPhone" i18n="ContactPhone">Contact phone</mat-label>
                      <input matInput type="text" formControlName="telefono" id="inputPhone" placeholder="" required>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputEmail" i18n="ContactEmail">Contact email</mat-label>
                      <input matInput type="text" formControlName="email" id="inputEmail" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>
              </form>
              <form *ngIf="esEmpresa" [formGroup]="contactoEmpresaFormGroup" class="form">
                <div class="form-row">
                  <div class="col-md-12">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputRut" i18n="RUTNumber">Rut</mat-label>
                      <input matInput type="text" formControlName="rut" id="inputRut" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputBusinesName" i18n="BusinessLegalName">Business legal name</mat-label>
                      <input matInput type="text" formControlName="razonSocial" id="inputBusinesName" placeholder="" required>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputCommercialName" i18n="CommercialName">Commercial name</mat-label>
                      <input matInput type="text" formControlName="nombreComercial" id="inputCommercialName" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputAddressE" i18n="Address">Address</mat-label>
                      <input matInput type="text" formControlName="domicilio" id="inputAddressE" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-2">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputZipE" i18n="ZipCode">Zip code</mat-label>
                      <input matInput type="text" formControlName="codigoPostal" id="inputZipE" placeholder="">
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputPaisE" i18n="CountryAddress | Label que pide el Pais Domicilio">Country</mat-label>
                      <mat-select formControlName="paisDomicilio" id="inputPaisE" required>
                        <mat-option *ngFor="let pais of paises$ | async" [value]="pais">{{pais.nombre}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputStateE" i18n="State">State</mat-label>
                      <input matInput type="text" formControlName="departamento" id="inputStateE" placeholder="">
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputCiudadE" i18n="City">City</mat-label>
                      <input matInput type="text" formControlName="ciudad" id="inputCiudadE" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputPhoneE" i18n="ContactPhone">Contact phone</mat-label>
                      <input matInput type="text" formControlName="telefono" id="inputPhoneE" placeholder="" required>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field appearance="fill">
                      <mat-label for="inputEmailE" i18n="ContactEmail">Contact email</mat-label>
                      <input matInput type="text" formControlName="email" id="inputEmailE" placeholder="" required>
                    </mat-form-field>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <!-- Sidebar -->
          <aside class="col-lg-5 mt-4 mt-lg-0 mat-step__aside">
            <mat-card *ngIf="this.appraisal != undefined" class="grey-card summary-card mat-elevation-z0">
              <mat-card-content class="summary">
                <h2 class="mat-typography__underscore mb-4" i18n="SummaryTitle | Tile summary">Summary</h2>
                <!-- <mat-card-title i18n="SummaryTitle | Tile summary">Summary</mat-card-title> -->
                <div class="summary__item">
                  <span class="summary__category">Plan</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombrePlan != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <strong>{{linea.nombrePlan}}</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{linea.importeBruto}}</strong></span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="summary__item" *ngIf="this.recursos.length > 0">
                  <span i18n="AddonTitle | titulo de complementos en el resumen de orden" class="summary__category">Add-ons</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombreRecurso != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'"><span class="summary__count">{{linea.cantidad}}x</span></ng-container>
                        {{linea.nombreRecurso}}
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'">*</ng-container>
                      </span>
                      <span class="summary__value">
                        <span>{{this.appraisal.moneda.codigo}}</span>
                        <span>{{linea.importeBruto}}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="info my-4" *ngIf="this.OnetimeCost" i18n="OneTimeCostDisclaimer | costos unicos aclaracion">* One-time costs</div>
                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name">Subtotal</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeBruto}}</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name" i18n="TaxesSummary | Titulo impuestos en el resumen de orden">Taxes</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeImpuestos}}</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item summary__item--highlighted">
                  <div class="summary__item-row">
                    <span class="summary__name">
                      <strong i18n="PurchaseTotalComplements | Titulo Total mensual">Purchase Total</strong>
                    </span>
                    <span class="summary__value">
                      <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                      <span><strong>{{this.appraisal.importeTotal}}</strong></span>
                    </span>
                  </div>
                </div>
                <div *ngIf="appraisal.importeAnticipado > 0">
                  <hr/>
                  <div class="summary__item summary__item--highlighted">
                    <div class="summary__item-row">
                      <span class="summary__name">
                        <strong i18n="PrepaidTitlePurchaseService | Titulo pago anticipado">Amount to pay now</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{this.appraisal.importeAnticipado}}</strong></span>
                      </span>
                    </div>
                  </div>
                  <p class="small mt-3" i18n="DescriptionPrepaidService | descripcion de pago anticipado servicio">
                    You must pay this amount online to proceed with your purchase
                  </p>
                </div>
              </mat-card-content>
            </mat-card>
          </aside>
        </div>
        <div class="mat-step__footer">
          <button mat-flat-button color="white" (click)="goToBack()" i18n="BackButtonContactClientStep">Back</button>
          <button mat-flat-button color="primary" class="ml-4" (click)="crearModificarClienteYContacto()" i18n="SaveAndContinue">Save and Continue</button>
        </div>
      </mat-step>
      <mat-step [editable]="this.editable">
        <ng-template i18n="Order confirmation| La etiqueta de confirmacion de orden" matStepLabel>Order confirmation</ng-template>
        <div class="row">
          <div class="col-lg-7">
            <div class="mat-step__wrapper">
              <h1 class="mat-typography__underscore mb-5" i18n="OrderConfirmationTitle | Titulo Confirmacion de orden ultimo paso carrito">Order confirmation</h1>

              <mat-card class="grey-card mat-elevation-z0">
                <mat-card-title i18n="BillingAccountTitle | Cuenta de facturacion titulo">Billing Account</mat-card-title>
                <mat-card-content class="account">
                  <div class="account__name">
                    {{this.clienteContacto?.cliente?.nombre}} <strong>{{this.clienteContacto?.cliente?.tipo}}</strong>
                  </div>
                  <hr/>
                  <div class="account__name mt-3">
                    <span *ngIf="this.clienteContacto?.contacto?.esPersona">{{this.clienteContacto?.contacto?.nombre}} {{this.clienteContacto?.contacto?.apellido}}</span>
                    <span *ngIf="this.clienteContacto?.contacto?.esEmpresa">{{this.clienteContacto?.contacto?.razonSocial}} ({{this.clienteContacto?.contacto?.nombreComercial}})</span>
                  </div>
                  <div class="account__document">
                    <span *ngIf="esPersona"> {{this.clienteContacto?.contacto?.tipoDocumento}}</span>
                    <span *ngIf="esPersona && this.clienteContacto?.contacto?.paisDocumento">{{this.lang == 'es' ? this.clienteContacto?.contacto?.paisDocumento?.nombre : this.clienteContacto?.contacto?.paisDocumento?.nombreEng}}</span>
                    <span *ngIf="esPersona">{{this.clienteContacto?.contacto?.numeroDocumento}}</span>
                  </div>

                  <ul class="account__moreinfo">
                    <li *ngIf="this.clienteContacto?.contacto?.email != undefined">
                      <mat-icon>email</mat-icon>
                      {{this.clienteContacto?.contacto?.email}}</li>
                    <li *ngIf="this.clienteContacto?.contacto?.telefono != undefined">
                      <mat-icon>phone</mat-icon>
                      {{this.clienteContacto?.contacto?.telefono}}</li>
                    <li *ngIf="this.clienteContacto?.contacto?.domicilio != undefined">
                      <mat-icon>home</mat-icon>
                      {{this.clienteContacto?.contacto?.domicilio}}</li>
                    <li *ngIf="this.clienteContacto?.contacto?.codigoPostal != undefined || this.clienteContacto?.contacto?.ciudad != undefined || this.clienteContacto?.contacto?.departamento != undefined">
                      <mat-icon>location_city</mat-icon>
                      <span *ngIf="this.clienteContacto?.contacto?.codigoPostal != undefined">({{this.clienteContacto?.contacto?.codigoPostal}})&nbsp;</span>
                      <span>{{this.clienteContacto?.contacto?.ciudad}}</span>
                      <span *ngIf="this.clienteContacto?.contacto?.departamento != undefined">, {{this.clienteContacto?.contacto?.departamento}}</span>
                    </li>
                  </ul>
                </mat-card-content>
              </mat-card>

              <mat-card class="grey-card mat-elevation-z0 mt-4">
                <!-- <mat-card-title i18n="DiscountCodeTitle | Titulo codigo de descuento ultimo paso del carrito">Discount Code</mat-card-title> -->
                <mat-card-content class="discount">
                  <!-- <p class="mb-3" i18n="DiscountCodeLabel | Label de codigo de descuento en pagina de compra">Do you have a discount code? Enter it here!</p> -->
                  <form [formGroup]="cuponForm" class="form">
                    <mat-form-field appearance="fill">
                      <mat-label i18n="DiscountCode | Codigo de descuento en purchase page">Do you have a discount code? Enter it here!</mat-label>
                      <input matInput type="text" formControlName="cupon">
                      <!-- placeholder="DISCOUNT CODE" i18n-placeholder="DiscountCode | Codigo de descuento placeholder en purchase page" -->
                    </mat-form-field>
                  </form>
                  <div class="text-center mt-4">
                    <button class="mat-button--block" mat-flat-button color="accent" i18n="ApplyCodeButton | Boton Aplicar codigo de descuento" (click)="calculatePrice()">Apply</button>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card *ngIf="product?.infoTermsHtml !== undefined" class="grey-card mat-elevation-z0 mt-4">
                <mat-card-title i18n="TermsAndConditionsServiceTitle | Terminos y condiciones del Servicio ultimo paso del carrito">Terms & conditions of the service</mat-card-title>
                <mat-card-content>
                  <div class="text-html" [innerHTML]="product?.infoTermsHtml | safeHtml:'html'"></div>
                </mat-card-content>
              </mat-card>

              <mat-card *ngIf="product?.infoTermsTiendaHtml !== undefined" class="grey-card mat-elevation-z0 mt-4">
                <mat-card-title i18n="TermsAndConditionsStoreTitle | Terminos y condiciones de la tienda ultimo paso del carrito">Terms & conditions of the store</mat-card-title>
                <mat-card-content>
                  <div class="text-html" [innerHTML]="product?.infoTermsTiendaHtml | safeHtml:'html'"></div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- Sidebar -->
          <aside class="col-lg-5 mt-4 mt-lg-0 mat-step__aside">
            <mat-card *ngIf="this.appraisal != undefined" class="grey-card summary-card mat-elevation-z0">
              <mat-card-content class="summary">
                <h2 class="mat-typography__underscore mb-4" i18n="SummaryTitle | Tile summary">Summary</h2>
                <!-- <mat-card-title i18n="SummaryTitle | Tile summary">Summary</mat-card-title> -->
                <div class="summary__item">
                  <span class="summary__category">Plan</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombrePlan != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <strong>{{linea.nombrePlan}}</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{linea.importeBruto}}</strong></span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="summary__item" *ngIf="this.recursos.length > 0">
                  <span i18n="AddonTitle | titulo de complementos en el resumen de orden" class="summary__category">Add-ons</span>
                  <ng-container *ngFor="let linea of this.appraisal.lineasCfe">
                    <div *ngIf="linea.nombreRecurso != undefined" class="summary__item-row">
                      <span class="summary__name">
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'"><span class="summary__count">{{linea.cantidad}}x</span></ng-container>
                        {{linea.nombreRecurso}}
                        <ng-container *ngIf="linea?.tipo_recurso === 'CONTINGENTE'">*</ng-container>
                      </span>
                      <span class="summary__value">
                        <span>{{this.appraisal.moneda.codigo}}</span>
                        <span>{{linea.importeBruto}}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div class="info my-4" *ngIf="this.OnetimeCost" i18n="OneTimeCostDisclaimer | costos unicos aclaracion">* One-time costs</div>
                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name">Subtotal</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeBruto}}</span>
                    </span>
                  </div>
                </div>

                <div *ngIf="obtenerDescuento()>0" class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name" i18n="PurchaseDescuento  | DESCUENTO ">Discount {{ this.appraisal.lineasCfe[0].codigo_promocion}} </span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{obtenerDescuento()}}</span>
                    </span>
                  </div>
                </div>

                <div *ngIf="obtenerDescuento()>0" class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name" i18n="PurchaseMontoImponible | MONTO IMPONIBLE">Taxable amount</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.montoImponible}}</span>
                    </span>
                  </div>
                </div>


                <div class="summary__item summary__item--bg">
                  <div class="summary__item-row">
                    <span class="summary__name" i18n="TaxesSummary | Titulo impuestos en el resumen de orden">Taxes</span>
                    <span class="summary__value">
                      <span>{{this.appraisal.moneda.codigo}}</span>
                      <span>{{this.appraisal.importeImpuestos}}</span>
                    </span>
                  </div>
                </div>
                <div class="summary__item summary__item--highlighted">
                  <div class="summary__item-row">
                    <span class="summary__name">
                      <strong i18n="PurchaseTotalComplements | Titulo Total mensual">Purchase Total</strong>
                    </span>
                    <span class="summary__value">
                      <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                      <span><strong>{{this.appraisal.importeTotal}}</strong></span>
                    </span>
                  </div>
                </div>
                <div *ngIf="appraisal.importeAnticipado > 0">
                  <hr/>
                  <div class="summary__item summary__item--highlighted">
                    <div class="summary__item-row">
                      <span class="summary__name">
                        <strong i18n="PrepaidTitlePurchaseService | Titulo pago anticipado">Amount to pay now</strong>
                      </span>
                      <span class="summary__value">
                        <span><strong>{{this.appraisal.moneda.codigo}}</strong></span>
                        <span><strong>{{this.appraisal.importeAnticipado}}</strong></span>
                      </span>
                    </div>
                  </div>
                  <p class="small mt-3" i18n="DescriptionPrepaidService | descripcion de pago anticipado servicio">
                    You must pay this amount online to proceed with your purchase
                  </p>
                </div>
              </mat-card-content>
            </mat-card>
          </aside>
        </div>
        <div class="mat-step__footer">
          <button mat-flat-button color="white" (click)="goToBack()" i18n="BackButton | Boton atras en el ultimo paso del carrito">Back</button>
          <button mat-flat-button color="primary" class="ml-4" (click)="crearOrdenCompraPagoAnticipado()" i18n="PlaceOrderButton | Boton realizar orden">
            Place Order
            <mat-icon *ngIf="busy">
              <mat-spinner color="accent" diameter="20"></mat-spinner>
            </mat-icon>
          </button>
        </div>
      </mat-step>
      <mat-step [editable]="this.editable">
        <ng-template matStepLabel i18n="receipt of Order| La etiqueta recibo de pedido">Order receipt</ng-template>
        <div class="mat-step__wrapper text-center">
          <mat-icon class="mat-icon--lg">check</mat-icon>
          <h2 class="mb-5" i18n="ThankYou">Thank You!</h2>
          <p i18n="InstructionParagraph">Please check your <strong>email</strong> for further instructions on how to use your subscription.</p>
          <br>
          <small i18n="HavingATrouble">Having trouble? <a href="#">Contact us</a></small>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>

<form #hiddenForm [formGroup]="hiddenFormGroup">
  <ng-container *ngIf="paymentGatewayData">
    <input name="idOrganismo" [value]="paymentGatewayData.idOrganismo" type="hidden"/>
    <input name="tipoServicio" [value]="paymentGatewayData.tipoServicio" type="hidden"/>
    <input name="idBanco" [value]="paymentGatewayData.idBanco" type="hidden"/>
    <input name="idTransaccion" [value]="paymentGatewayData.idTransaccion" type="hidden"/>
    <input name="idCuenta" [value]="paymentGatewayData.idCuenta" type="hidden"/>
    <input name="idFactura" [value]="paymentGatewayData.idFactura" type="hidden"/>
    <input name="importe" [value]="paymentGatewayData.importe" type="hidden"/>
    <input name="importeGravado" [value]="paymentGatewayData.importeGravado" type="hidden"/>
    <input name="consumidorFinal" [value]="paymentGatewayData.consumidorFinal" type="hidden"/>
    <input name="moneda" [value]="paymentGatewayData.moneda" type="hidden"/>
    <input name="urlVuelta" [value]="paymentGatewayData.urlVuelta" type="hidden"/>
    <button mat-button (click)="payNow()" color="primary" class="btn-outline">Pay Now</button>
  </ng-container>
</form>
