<div class="appDetailsContent">
  <div class="breadcrumb"><!-- appCats -->
    <div class="container">
      <a [routerLink]="'/'" i18n="Home">Home</a>
      <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span i18n="Subscriptions">Subscriptions</span>
    </div>
  </div>
</div>
<div class="container">
  <ng-container *ngIf="vacio">
    <div class="d-flex flex-column justify-content-center" style="min-height: 500px;">
        <div class="d-flex flex-row justify-content-center">
          <p i18n="NotsSubsMessage | mensaje de que no tiene subs"><b>You do not have active subscriptions</b></p>
        </div>
    </div>
  </ng-container>
  <mat-list class="list-orders" role="list">
    <mat-list-item *ngFor="let contrato of this.suscripciones" role="listitem">
      <mat-card class="order-card mat-elevation-z0">
        <mat-card-title-group>
          <img mat-card-image src="{{contrato?.plan?.servicio?.imgUrl}}" alt="{{contrato?.plan?.servicio?.nombre}}" />
          <div>
            <mat-card-title>
              <span class="link-style" (click)="redirect(contrato.id)">{{contrato?.plan?.servicio?.nombre}}</span>
            </mat-card-title>
            <mat-card-subtitle>
              <span i18n="IdSuscripcionLabel | Etiqueta Id Suscripcion en la card de suscripcion">Contract: </span>
              <strong>#{{contrato?.idSuscripcionExterno}}</strong>
            </mat-card-subtitle>
            <div class="state">
              <span i18n="StateLabel | Etiqueta Estado en la card de suscripcion">State: </span>
              <strong> {{contrato?.estado?.nombre}}</strong>
            </div>
          </div>
        </mat-card-title-group>

        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <ul role="list">
                <li role="listitem">
                  <span i18n="PlanLabel | Etiqueta Plan en la card de suscripcion">Plan: </span>
                  <span>{{contrato?.plan?.nombre}}</span>
                </li>
                <li role="listitem">
                  <span i18n="BillingAccountLabel | Etiqueta Cuenta de facturacion en la card de suscripcion">Billing Account: </span>
                  <span>{{contrato?.cliente?.nombre}}</span>
                </li>
                <li role="listitem">
                  <span i18n="ValidFromLabel | Etiqueta Valido Hasta en la card de suscripcion">Valid from: </span>
                  <span><strong>{{contrato?.fechaInicioVigencia | date: 'shortDate'}}</strong></span>
                </li>
                <li role="listitem">
                  <span i18n="ValidUntilLabel | Etiqueta Valido Desde en la card de suscripcion">Valid until: </span>
                  <span><strong>{{contrato?.fechaFinVigencia | date: 'shortDate'}}</strong></span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row align-items-sm-end mt-3">
            <div class="col-sm-4 order-sm-2">
              <button mat-flat-button color="accent" class="mat-button-xs--block" (click)="redirect(contrato.id)">
                <span i18n="ManageSubscriptionButton | Boton administrar suscripcion">Manage</span>
              </button>
            </div>
            <div class="col-sm-8 order-sm-1">
              <a href="{{contrato.plan.servicio.servicioUrl}}" target="_blank" class="url-link">
                <mat-icon>open_in_new</mat-icon>
                <!-- <span i18n="UrlApp | Link de la app">{{contrato.plan.servicio.nombre}}</span> -->
                <span i18n="irAlSitio | Ir al sitio de ">Go to {{contrato.plan.servicio.nombre}}</span>
              </a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- averiguar que es esto -->
      <div [innerHTML]="contrato?.plan?.servicio?.soporteHtml">
      </div>
      <div [innerHTML]="contrato?.plan?.servicio?.productor?.nombre">
      </div>
    </mat-list-item>
  </mat-list>
</div>
