import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) {
  }

  crear(_idExternoCliente, _idReferencia, esPersona, total, importeGravado): Observable<any> {
    const _pago = {
        idExternoCliente: _idExternoCliente,
        idReferencia: _idReferencia,
        tipoReferencia: 'FACTURA',
        monedaCodigo: 'UYU',
        importe: total,
        importeGravado: importeGravado,
        consumidorFinal: esPersona,
        fechaVencimiento: '2020-11-30T08:30:00.744-03:00',
        fechaLimitePago: '2020-11-30T08:30:00.744-03:00',
      }
    ;
    return this.httpClient.post(
      environment.apiBase + '/tiendas/' + environment.idStore + '/pagos', _pago
    );
  }


  public getAll(limite: number, desplazamiento: number, fechaInicio: Date, fechaFin: Date, rangoDias: string, estado: string, tipo: string): Observable<any> {

    let params = new HttpParams();

    if (estado == "TODOS") {
      estado = null;
    }

    if (tipo == "TODOS") {
      tipo = null;
    }

    if (fechaInicio != null) {
      params = params.append('fechaDesde', fechaInicio.toString());
    }
    if (fechaFin != null) {
      params = params.append('fechaHasta', fechaFin.toString());
    }

    if (tipo != null) {
      params = params.append('tipoReferencia', tipo);
    }

    if (estado != null) {
      params = params.append('estadoPago', estado);
    }

    if (desplazamiento != null) {
      params = params.append('desplazamiento', desplazamiento.toString());
    }
    if (limite != null) {
      params = params.append('limite', limite.toString());
    }

    return this.httpClient.get<any>( environment.apiBase + '/tiendas/' + environment.idStore + "/pagos/usuario",
      { params }
    );
  }

}
