<div class="search-dialog">
  <button mat-dialog-close tabindex="-1" mat-icon-button class="close">
    <mat-icon color="accent">close</mat-icon>
  </button>
  <mat-dialog-content>
    <form [formGroup]="searchForm" class="form-search" (ngSubmit)="onSubmit(searchForm.value)">
      <mat-form-field floatLabel="never" appearance="standard">
        <input matInput type="search" formControlName="search" placeholder="Search for solutions..." i18n-placeholder="PlaceHolderDialog | Placeholder en el dialogo de busqueda">
        <button type="submit" mat-icon-button aria-label="Search" matSuffix>
          <mat-icon>search</mat-icon>
        </button>         
      </mat-form-field>
      <div class="msg msg--error" *ngIf="search.errors?.minlength" i18n="DisclaimerShortSearch | Aviso de que la busqueda debe ser de mas de 3 caracteres">
        The search must be at least 3 characters.
      </div>
    </form>
  </mat-dialog-content>
</div>

