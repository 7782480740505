import { Component, Input, OnInit  } from '@angular/core';
import { ProductService} from '@services';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {Product} from '@models';
import {switchMap, tap} from "rxjs/operators";
import {ActivatedRoute} from '@angular/router';
import {CategoryService} from '@services';
import {Category} from '@models';


@Component({
  selector: 'app-banner-aplicacion',
  templateUrl: './banner-aplicacion.component.html',
  styleUrls: ['./banner-aplicacion.component.scss']
})
export class BannerAplicacionComponent implements OnInit {

  public categories: Category[];
  public products$: Observable<Product[]>;

  @Input() element:any;
  constructor(private productService: ProductService, route: ActivatedRoute, private categoryService: CategoryService,) { 

    this.products$ = route.queryParams.pipe(
      switchMap(params => this.productService.getProductsByCategoryWithOrder$(environment.homeCategory, params.tipo_orden, params.ordenar))
    );
  }

  ngOnInit(): void {

    this.categoryService.getChildrenDeepSearch$(environment.homeCategory).subscribe(data => {
          //console.log('CATEGORIA', data);
          this.categories = data;
    });
      
      
      //data.category);
    
    // this.categoryService.getAll$().subscribe(data => {
    //   console.log('CATEGORIA', environment.homeCategory, data);
    //   this.categories = data;
    //   //debugger;
    // });
  }

}
