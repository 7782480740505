import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Category, Product} from '@models';
import {Observable} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";

// import Swiper core and required components
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from "swiper/core";

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  EffectFade,
]);

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html'
})
export class ProductSliderComponent implements OnInit {

  public ordenar = '';
  public tipo_orden = '';

  itemsPerPage: number;

  @Input()
  products: Product[];

  @Input()
  busqueda = false;

  @Input()
  destacadas: boolean;

  @Input()
  category: Category;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<Product[]>;

  @Input() categories: Category[];

  configCarousel: SwiperOptions = {
    slidesPerView: 1,
    navigation: true,
    // navigation: {
    //   prevEl: '.carousel-nav-prev',
    //   nextEl: '.carousel-nav-next'
    // },
    spaceBetween: 16,
    // Responsive breakpoints
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      }
    }
  };


  constructor(private router: Router, private route: ActivatedRoute) {
    this.itemsPerPage = environment.itemsPerPage;
  }

  ngOnInit(): void {

  }

  OrderByNameToogle() {
    this.tipo_orden = 'ALFABETICO';
    if (this.ordenar === 'ASC') {
      this.ordenar = 'DESC';
    }
    else if(this.ordenar === 'DESC'){
      this.tipo_orden = null;
      this.ordenar = null;
    }
    else {
      this.ordenar = 'ASC';
    }

    this.router.navigate([], {
      queryParams: {
        ordenar: this.ordenar,
        tipo_orden: this.tipo_orden
      },
      queryParamsHandling: 'merge'
    });
  }

  OrderByPriceToogle() {
    this.tipo_orden = 'PRECIO';
    if (this.ordenar === 'ASC') {
      this.ordenar = 'DESC';
    }  else if(this.ordenar === 'DESC'){
      this.tipo_orden = null;
      this.ordenar = null;
    }
    else {
      this.ordenar = 'ASC';
    }
    this.router.navigate([], {
      queryParams: {
        ordenar: this.ordenar,
        tipo_orden: this.tipo_orden
      },
      queryParamsHandling: 'merge'
    });
  }

}
