import {Injectable} from '@angular/core';
import {PlanRecurso} from '../models/planRecurso.model';
import {Plan} from '../models/plan.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {OrdenPlanCompraRecurso} from '../models/ordenplancomprarecurso.model';
import {Appraisal} from "../models/appraisal.model";
import {ComplementoRecurso} from "../models/complementoRecurso.model";
import {Contrato} from "../models/contrato.model";
import {Client} from "../models/client.model";
import {LangService} from './lang.service';


@Injectable({
  providedIn: 'root'
})
export class AppraisalService {

  constructor(private httpClient: HttpClient, private langService: LangService) {

  }

  public calculatePrice(plan: Plan, quantity: number, recursos: OrdenPlanCompraRecurso[], cupon?: string, clientId?: number): Observable<Appraisal> {
    const recursosAux: OrdenPlanCompraRecurso[] = [];
    let params = new HttpParams();
    params = params.append('lang', this.langService.getLang());
    recursos.forEach(res => {
      // @ts-ignore
      recursosAux.push({cantidad: res.cantidad, recurso: {id: res.recurso.id}});
    });
    const appraisalRequest = {plan: {id: plan.id}, cantidad: quantity, complementos: recursosAux, codigoPromocion: cupon, clientId};
    return this.httpClient.post<Appraisal>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/tasacionesCompra',
     //'http://localhost:8080/suscripciones-backend' + '/tiendas/' + environment.idStore + '/tasacionesCompra',
      appraisalRequest , {params}
    );
  }

  public calculatePriceCancelacion(plan: Plan, contrato: Contrato, cliente: Client, quantity: number, recursos: ComplementoRecurso[]): Observable<Appraisal> {
    const recursosAux: OrdenPlanCompraRecurso[] = [];
    let params = new HttpParams();
    params = params.append('lang', this.langService.getLang());
    recursos.forEach(res => {
      // @ts-ignore.
      recursosAux.push({cantidad: res.cantidad, recurso: {id: res.planRecurso.recurso.id}});
    });
    const appraisalRequest = { idContrato: contrato.id };
      return this.httpClient.post<Appraisal>(
        environment.apiBase + '/tiendas/' + environment.idStore + '/tasacionesCancelacionPlan',
        appraisalRequest , {params}
      );
  }

  public calculatePriceCancelationComponent(id: number): Observable<Appraisal>{
    const appraisalRequest = { idComplemento: id };
    let params = new HttpParams();
    params = params.append('lang', this.langService.getLang());
    return this.httpClient.post<Appraisal>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/tasacionesCancelacionComplemento',
      appraisalRequest, {params}
    );
  }
}
