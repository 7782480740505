import { Component, Input, OnInit } from "@angular/core";
import { Category } from "@models";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-list",
  templateUrl: "./category-list.component.html",
  styleUrls: ["./category-list.component.scss"],
})
export class CategoryListComponent {
  @Input() categories: Category[];

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
