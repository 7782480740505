import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: environment.multisite ? '/src/multisite/' + environment.multisite + '/layouts/footer/footer.component.html' : './footer.component.html',
  //templateUrl: '/src/multisite/fibras/layouts/footer/footer.component.html',
  styleUrls: []
})
export class FooterComponent implements OnInit {
 
  IsEnglish = false;
  public email = environment.email;
  constructor() {
  }
  
  ngOnInit(): void {
  }

  // public categories$: Observable<Category[]>;
  // public categories: Category[];

  // constructor(private categoryService: CategoryService,
  //             private loginService: LoginService) {
  // }

  // ngOnInit(): void {
  //   this.categories$ = this.categoryService.getAll$();
  // }


  // login() {
  //   this.loginService.login();
  // }

  // register() {
  //   this.loginService.login();
  // }

  changeLanguage(language: string) {
    // localStorage.setItem('locale', language);
    window.location.href = environment.urlApp + language;
  }
}
