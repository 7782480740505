import {KeycloakService} from 'keycloak-angular';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {KeycloakProfile} from 'keycloak-js';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  public user$ = new BehaviorSubject<KeycloakProfile>(null);
  public user: KeycloakProfile;


  constructor(private keycloakService: KeycloakService) {
    this.keycloakService.isLoggedIn().then(status => {
      if (status) {
        this.keycloakService.loadUserProfile().then(u => {
          this.user = u;
          this.user$.next(u);
        });
      }
    });
  }

  public logOut(redirect?) {
    // this.keycloakService.logout(redirect);
    this.keycloakService.logout(redirect);
  }

  public login() {
    this.keycloakService.login({idpHint: environment.idpHint});
  }

  public register() {
    this.keycloakService.register({idpHint: environment.idpHint});
  }

  // public change(){
  //   this.keycloakService.
  // }
  public getLoggedUser() {
    return this.user;
  }
}
