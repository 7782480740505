import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ContratoUsuario} from '../models/contratoUsuario.model';


@Injectable({
  providedIn: 'root'
})
export class ContractUserService {

  constructor(private httpClient: HttpClient) {
  }

  public create(contratoId: number, perfilId: number, email: string, nombre: string, apellido: string): Observable<any> {
    const contratoUsuarioRequest = {perfil: {id: perfilId}, email, nombre, apellido};
    return this.httpClient.post<any>(
     // 'http://localhost:8080/suscripciones-backend'  + '/tiendas/' + environment.idStore + '/contratos/' + contratoId + '/usuario/invitaciones',
     environment.apiBase + '/tiendas/' + environment.idStore + '/contratos/' + contratoId + '/usuario/invitaciones',
      contratoUsuarioRequest
    );
  }

  public obtenerTodos(contratoId: number): Observable<ContratoUsuario[]> {
    return this.httpClient.get<ContratoUsuario[]>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/contratos/' + contratoId + '/invitaciones'
    );
  }

  public eliminar(contratoId: number, contratoUsuarioId: number): Observable<any> {
    return this.httpClient.delete<any>(
      environment.apiBase + '/tiendas/' + environment.idStore + '/contratos/' + contratoId + '/invitaciones/' + contratoUsuarioId
    );
  }

}
