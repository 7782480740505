import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@utils';
import {OrderService} from '../../../services/order.service';
import {Orden} from '../../../models/orden.model';

@Component({
  selector: 'app-services',
  templateUrl: './services.page.html',
  styleUrls: [],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},

  ]
})
export class ServicesPage implements AfterViewInit, OnInit {
  searchForm: FormGroup;
  // displayedColumns = ['idSuscripcion', 'estado', 'NombrePlan', 'IdPlan', 'FechaCreado'];
  displayedColumns = ['id', 'clienteId', 'contactoId', 'tipo', 'estado'];
  dataSource: MatTableDataSource<Orden>;
  tipo: any[];
  ordenes: Orden[];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private orderService: OrderService) {

  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
  }

  onSubmit(value: any) {

  }

  ngOnInit(): void {
    this.orderService.getProductSubscriptions$().subscribe(res => {
      this.ordenes = res;
      this.dataSource = new MatTableDataSource<Orden>(res);
    });
    this.searchForm = new FormGroup({
      tipo: new FormControl(null, [Validators.nullValidator]),
      estado: new FormControl(null, [Validators.nullValidator]),
      fechaDesde: new FormControl(null, Validators.nullValidator),
      fechaHasta: new FormControl(null, Validators.nullValidator),
      plan: new FormControl(null, [Validators.nullValidator]),
      complemento: new FormControl(null, [Validators.nullValidator])
    });
  }
}



