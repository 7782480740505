import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Client} from '../models/client.model';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClienteContactoService {

  constructor(private httpClient: HttpClient) {
  }

  public getAll$(): Observable<Client[]> {
    let params = new HttpParams();
    params = params.append('tiendaId', environment.idStore.toString());
    return this.httpClient.get<Client[]>(environment.apiBase + '/clientes/usuario',{params});
  }

  public create(clienteContacto) {
    return this.httpClient.post(environment.apiBase + '/clientes/usuario', clienteContacto);
  }

  public update(id, clienteContacto) {
    return this.httpClient.put(environment.apiBase + '/clientes/usuario/' + id, clienteContacto);
  }
}
