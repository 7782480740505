import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  public config: MatSnackBarConfig;

  constructor(public snackbar: MatSnackBar) {
  }

  public handleError(error: any) {
    this.config = new MatSnackBarConfig();
    this.config.duration = 5000;
    if (error.status === 0) {
      this.snackbar.open('No se pudo conectar con el servidor', 'Cerrar', this.config);
    } else {
      let mensaje = '';
      if (error && error.error && error.error.errores && error.error.errores.length > 0) {
        const mensajes = [];
        error.error.errores.forEach(e => {
          mensajes.push(e.descripcion);
        });
        mensaje = mensajes.join('\n');
      } else {
        mensaje = error.error.replace(/&quot;/g, '"');
      }
      this.snackbar.open(mensaje, 'Cerrar', this.config);
    }
  }
}
