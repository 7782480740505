import { Component, Input, OnInit } from "@angular/core";
import { Category } from "@models";
import { Router } from "@angular/router";

// import Swiper core and required components
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from "swiper/core";

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  EffectFade,
]);

@Component({
  selector: "app-category-slider",
  templateUrl: "./category-slider.component.html"
})
export class CategorySliderComponent {
  @Input() categories: Category[];

  configCarousel: SwiperOptions = {
    slidesPerView: 2,
    // navigation: true,
    navigation: {
      prevEl: '.carousel-nav-prev',
      nextEl: '.carousel-nav-next'
    },
    spaceBetween: 16,
    // Responsive breakpoints
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 5,
      }
    }
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
