import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {
  searchForm: FormGroup;
  flag: boolean
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.flag = false;
    this.searchForm = new FormGroup({
      search: new FormControl('', Validators.minLength(3))
    });
  }


  get search() {
    return this.searchForm.get('search');
  }

  onSubmit(value: any) {
    if (!this.searchForm.get('search').errors?.minlength && this.search.value) {
      this.dialogService.close();
      this.router.navigate(['search/' + value.search], {relativeTo: this.route});
    } else if (!this.search.value) {
      this.searchForm.controls.search.setErrors({ minlength: { requiredLength: 3, actualLength: 0 } });
    }
  }
}
