<div class="container">
  <div class="row">
    <div class="col-12 mt-4">
      <h1 *ngIf="destacadas" i18n="TitleHomePage | titulo de la pagina soluciones relacionadas que esta en el inicio" class="mat-typography__underlined mb-0">Featured Solutions</h1>
      <h1 *ngIf="!destacadas" class="mat-typography__underlined mb-0">{{category?.nombre}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="order-apps mt-3 mb-3"><!-- order_apps -->
<!--        <ng-container *ngIf="busqueda">-->
<!--          <button mat-stroked-button class="orderAlph" (click)="OrderByName()">-->
<!--            A - Z-->
<!--            <img *ngIf="this.searchService.getValue().tipoOrden === 'DESC' && this.searchService.getValue().ordenadoPor === 'ALFABETICO'" src="assets/images/icons/order_price.svg" alt="order Price">-->
<!--            <img *ngIf="this.searchService.getValue().tipoOrden === 'ASC' && this.searchService.getValue().ordenadoPor === 'ALFABETICO'" src="assets/images/icons/order_price.svg" alt="order Price" class="arrowsPrice">-->
<!--          </button>-->
<!--          <button mat-stroked-button class="orderPrice" (click)="OrderByPrice()">-->
<!--            <span i18n="PriceButton | Etiqueta del boton de precio">Price</span>-->
<!--            <img *ngIf="this.searchService.getValue().tipoOrden === 'DESC' && this.searchService.getValue().ordenadoPor === 'PRECIO'" src="assets/images/icons/order_price.svg" alt="order Price">-->
<!--            <img *ngIf="this.searchService.getValue().tipoOrden === 'ASC' && this.searchService.getValue().ordenadoPor === 'PRECIO'" src="assets/images/icons/order_price.svg" alt="order Price" class="arrowsPrice">-->
<!--          </button>-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!busqueda">-->
          <button mat-stroked-button class="orderAlph" (click)="OrderByNameToogle()">
            A - Z
            <img *ngIf="this.ordenar === 'DESC' && this.tipo_orden === 'ALFABETICO'" src="assets/images/icons/order_price.svg" alt="order Price">
            <img *ngIf="this.ordenar === 'ASC' && this.tipo_orden === 'ALFABETICO'" src="assets/images/icons/order_price.svg" alt="order Price" class="arrowsPrice">
          </button>
          <button mat-stroked-button class="orderPrice" (click)="OrderByPriceToogle()">
            <span i18n="PriceButton | Etiqueta del boton de precio">Price</span>
            <img *ngIf="this.ordenar === 'DESC' && this.tipo_orden === 'PRECIO'" src="assets/images/icons/order_price.svg" alt="order Price">
            <img *ngIf="this.ordenar === 'ASC' && this.tipo_orden === 'PRECIO'" src="assets/images/icons/order_price.svg" alt="order Price" class="arrowsPrice">
          </button>
<!--        </ng-container>-->
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="app_featureds">
        <!-- <h2>{{category.nombre}}</h2> -->
        <mat-list class="list-products" role="list">
          <mat-list-item *ngFor="let product of products" role="listitem">
            <app-product-list-item [busqueda]="this.busqueda" [product]="product" class="product-list-item"></app-product-list-item>
          </mat-list-item>
          <!-- <mat-list-item *ngFor="let product of products" role="listitem"> -->
            <!-- <app-product-list-item [product]="product"></app-product-list-item> -->
          <!-- </mat-list-item> -->
        </mat-list>
      </div>
    </div>
  </div>
</div>
<mat-paginator *ngIf="itemsPerPage < products?.length" pageSize="{{itemsPerPage}}" ></mat-paginator>
