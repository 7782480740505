import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CategoryService, ProductService} from '@services';
import {Observable} from 'rxjs';
import {Category, Product} from '@models';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, switchMap} from 'rxjs/operators';
import {SearchBoxService} from '../../services/search-box.service';
import {SearchService} from '../../services/search.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search-page.html',
  styleUrls: []
})
export class SearchPage implements OnInit {
  searchForm: FormGroup;
  //public categories$: Observable<Category[]>;
  search: string;
  public products$: Observable<Product[]>;

  constructor(private categoryService: CategoryService,
              private route: ActivatedRoute,
              private productsService: ProductService,
              private router: Router,
              private searchBoxService: SearchBoxService,
              private searchService: SearchService,
              private titleService: Title) {


  }

  ngOnInit(): void {
    this.searchBoxService.hideSearchBox();
    // this.categories$ = this.categoryService.getAll$();
    this.search = this.route.snapshot.paramMap.get('search')
    this.searchForm = new FormGroup({
      search: new FormControl(this.search)
    });
    this.products$ = this.route.params.pipe(
      filter(data => data.search),
      switchMap(data => this.productsService.searchProduct$(data.search, 'ALFABETICO', 'ASC'))
    );
    this.searchService.busqueda$.asObservable().subscribe((value) => {
      this.products$ = this.route.params.pipe(
        filter(data => data.search),
        switchMap(data => this.productsService.searchProduct$(data.search, value.ordenadoPor, value.tipoOrden))
      );
    });
    this.titleService.setTitle('Resultados para "' + this.search + '"');
  }

  onSubmit(value: any) {
    this.router.navigate(['search/' + value.search]);
  }

}
