<div class="container">
  <div class="row">
    <div class="col-12 d-flex flex-row mt-5 mb-4">
      <h2 i18n="NotificationsPage | titulo de la pagina soluciones relacionadas que esta en el inicio">Notifications</h2>
    </div>
  </div>
<table mat-table [dataSource]="dataSource" class="tabla-generica center" matSort>

  <tr class="mat-row" *matNoDataRow>
    <td i18n="notificationNotFound | dialogo de notificacion "class="mat-cell" colspan="9999">
      Notifications not found
    </td>
  </tr>

  <ng-container matColumnDef="tipoId">
    <th mat-header-cell *matHeaderCellDef class="td-tipo"></th>
    <td mat-cell *matCellDef="let entidad" class="td-tipo"><br> <mat-icon>{{entidad?.tipoId}}</mat-icon></td>
  </ng-container>

  <ng-container matColumnDef="titulo" >
    <th mat-header-cell *matHeaderCellDef class="td-titulos"></th>
    <td mat-cell *matCellDef="let entidad" class="td-titulos"> <b style="font-style: italic; color: red;">{{entidad?.fechaCreado | date :"dd/MM/yyyy"}}</b>
      <br><strong>{{entidad?.titulo}}</strong>
      <br>{{entidad?.copete}}</td>
  </ng-container>

  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="td-acciones"></th>
    <td mat-cell *matCellDef="let entidad" class="td-acciones">
      <br>
      <button i18n-matTooltip="verTooltip | tooltip  ver notificacion "(click)="openNotification(entidad)" matTooltip="View" mat-icon-button>
        <mat-icon>remove_red_eye</mat-icon>
      </button>

      <ng-template [ngIf]="entidad.leido">
        <button i18n-matTooltip="unreadtooltip | tooltip maracar como no leido" (click)="readNotificationCheck(entidad, false)" matTooltip="Mark as unread" mat-icon-button>
          <mat-icon>mark_email_unread</mat-icon>
        </button>
      </ng-template>
      <ng-template [ngIf]="!entidad.leido">
        <button i18n-matTooltip="readtooltip |  tooltip maracar como  leido"(click)="readNotificationCheck(entidad, true)" matTooltip="Mark as read" mat-icon-button>
          <mat-icon>mark_email_read</mat-icon>
        </button>
      </ng-template>
      <button i18n-matTooltip="deleteToolTip | eliminar " (click)="deleteNotificationCheck(entidad, true)" matTooltip="Delete notification" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="sarasaaa" *matRowDef="let row; columns: displayedColumns;"></tr>
  </ng-container>

</table>
</div>
