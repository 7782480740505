<!-- {{element?.titulo}}
{{element?.linkValor}}
{{element?.tipoBanner}}
{{element?.imagen}}
{{element?.tipoVisualizacion}}
{{element?.ancho}}
{{element?.orden}}
{{element?.lenguaje}}
{{element?.fechaDesde | date:'shortDate'}}
{{element?.fechaFin | date:'shortDate'}} -->


<ngb-carousel interval="9000" pauseOnHover="true" showNavigationArrows="false" class="hero-slider full-width" *ngIf="element?.ancho === 0">
  <ng-template *ngFor="let item of element.itemCarrusel" ngbSlide>
    <a href="{{item?.linkValor}}">
      <img src="{{item?.imagen}}" alt="{{item?.titulo}}"
        [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}" />
    </a>
  </ng-template>
</ngb-carousel>

<ngb-carousel interval="9000" pauseOnHover="true" showNavigationArrows="false" class="hero-slider col-sm-{{element?.ancho}} p-2 text-center" *ngIf="element?.ancho !== 0">
  <ng-template *ngFor="let item of element.itemCarrusel" ngbSlide>
    <a href="{{item?.linkValor}}">
      <img src="{{item?.imagen}}" alt="{{item?.titulo}}"
        [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}" />
    </a>
  </ng-template>
</ngb-carousel>

