import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Product} from '@models';
import {environment} from '../../environments/environment';
import {LangService} from './lang.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public products: Product[];
  private products$ = new BehaviorSubject<Product[]>([]);

  constructor(private httpClient: HttpClient, private langService: LangService) {
    // this.httpClient.get<Product[]>(environment.apiBase + '/v2/tiendas/' + environment.store + '/servicios').pipe(
    //   tap((products: Product[]) => {
    //       this.products = products;
    //       this.products$.next(this.products);
    //     }
    //   )
    // ).subscribe();
  }

  public getAll$(): Observable<Product[]> {
    return this.products$;
  }

  getProductsByCategory$(idCategoria: string, idSubCategoria?: string): Observable<Product[]> {
    return this.httpClient.get<Product[]>(
      environment.apiBase + '/tiendas/' + environment.store + '/categorias/' + (idSubCategoria ? idSubCategoria : idCategoria) + '/servicios/activos'
    );
  }


  getProductsByCategoryWithOrder$(idCategoria: string, ordenPor: string, tipoOrden: string, idSubCategoria?: string): Observable<Product[]> {
    let params = new HttpParams();
    params = params.append('ordenar', ordenPor);
    params = params.append('tipo_orden', tipoOrden);
    params = params.append('lang', this.langService.getLang());

    return this.httpClient.get<Product[]>(
      environment.apiBase + '/tiendas/' + environment.store + '/categorias/' + (idSubCategoria ? idSubCategoria : idCategoria) + '/servicios/activos', {params}
    );
  }

  getProductByIdExterno$(productoIdExterno: string): Observable<Product> {
    let params = new HttpParams();

    params = params.append('lang', this.langService.getLang());

    return this.httpClient.get<Product>(
      environment.apiBase + '/tiendas/' + environment.store + '/servicios/' + productoIdExterno + '/activo',
      {params}
    );
  }

  imInterested$(productoIdExterno: string): Observable<any> {
    return this.httpClient.post<any>(environment.apiBase + '/tiendas/' + environment.store + '/servicios/' + productoIdExterno + '/interesado', {});
  }


  searchProduct$(search: string, ordenPor: string, tipoOrden: string): Observable<Product[]> {
    let params = new HttpParams();
    params = params.append('busqueda', search);
    params = params.append('ordenar', ordenPor);
    params = params.append('tipo_orden', tipoOrden);
    params = params.append('lang', this.langService.getLang());


    return this.httpClient.get<Product[]>(
      environment.apiBase + '/tiendas/' + environment.store + '/servicios/buscar', {params}
    );
  }


}
