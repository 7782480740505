<div class="appDetailsContent">
  <div class="breadcrumb"><!-- appCats -->
    <div class="container">
      <a [routerLink]="'/'" i18n="Home">Home</a>
      <ng-container *ngFor="let category$ of this.breadscrum">
        <ng-container *ngIf="category$ | async as category">
          <span> / </span>
          <a [routerLink]="'/'+category.fullIdExterno">{{category.nombre}}</a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<app-banner [category]="category"></app-banner>

<app-product-list [category]="category" [products]="products$ | async"></app-product-list>
