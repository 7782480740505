<h2 mat-dialog-title class="mat-typography__underscore" i18n="CancelSubTitle | titulo de cancelacion de suscripcion">
  Cancel Subscription</h2>
<mat-dialog-content>
  <p i18n="CancelSubExp | Explicacion de cancelacion de suscripcion">You are about to cancel the subscription
    #{{this.data.contrato.idSuscripcionExterno}}</p>

  <div *ngIf="this.tasacion !== undefined">
    <div *ngFor="let linea of tasacion.lineasCfe" class="box">
      <ng-container *ngIf="linea.tipo_recurso !== 'CONTINGENTE'">
        <h3 class="mb-3" *ngIf="linea.nombrePlan !== undefined" i18n="PlanDetailSub | detalle de plan subtitulo">
          <strong>Plan detail - {{linea.nombrePlan}}</strong></h3>
        <h3 class="mb-3" *ngIf="linea.nombrePlan == undefined" i18n="AddOnSub | detalle de recurso subtitulo">
          <strong>Add On - {{linea.nombreRecurso}}</strong></h3>
        <div class="row justify-content-between">
          <div class="col-7 pl-0">
            <span i18n="Cancelationcharge | cancelation charge cost">Cancellation charge</span>
          </div>
          <div class="">
            <strong>{{tasacion?.moneda?.codigo}} {{linea?.importeTotal}}</strong>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="box">
      <div class="row justify-content-between">
        <div class="col-7 pl-0">
          <span i18n="AmountTotalCancelation | label de precio de cancelacion">Cancellation Amount:</span>
        </div>
        <div class="">
          <strong>{{tasacion?.moneda?.codigo}} {{tasacion?.importeTotal}}</strong>
        </div>
      </div>
    </div>
    <div *ngIf="tasacion?.importeAnticipado > 0" class="box">
      <div class="row justify-content-between">
        <div class="col-7 pl-0">
          <span i18n="AmountToPayNowCancelation | monto a pagar ahora de cancelacion">Amount to pay now:</span>
        </div>
        <div class="">
          <strong>{{tasacion?.moneda?.codigo}} {{tasacion?.importeAnticipado }}</strong>
        </div>
      </div>
      <small class="mt-4" i18n="DescriptionPrepaidCancellation | descripcion de pago anticipado de cancelacion">You must
        pay this amount online to proceed with your cancellation. If the payment is not authorized, the monthly charges
        will continue to run and you will have to make the cancellation request again.</small>
    </div>
  </div>

  <div class="dialog-terms mt-4 mb-4">
    <form [formGroup]="checkBoxFormGroup">
      <mat-checkbox *ngIf="this.data?.contrato?.plan?.servicio?.infoTermsHtml != null" formControlName="checkBox1">
        <span i18n="CheckBox1TermsAndCond | primer checkbox de 2 de condiciones en cancelacion de sub">I accept the
          charges for cancellation</span>
      </mat-checkbox>
      <mat-checkbox *ngIf="this.data?.contrato?.plan?.servicio?.infoTermsHtml != null" formControlName="checkBox2">
        <span i18n="CheckBox2TermsAndCond | segundo checkbox de 2 de condiciones en cancelacion de sub">I accept the
          terms and conditions of subscription cancellation</span>
      </mat-checkbox>
      <div *ngIf="this.data?.contrato?.plan?.servicio?.infoTermsHtml != null" class="mt-2">
        <a href="#" class="text-link" (click)="redireccionarATermsAndCond()"
          i18n="TermsAndCondServiceLink | terminos y condiciones del servicio link">Terms & Conditions of the
          Service</a>
      </div>
    </form>
  </div>

  <div *ngIf="showError" class="alert alert-danger">
    <span i18n="TermsAndCondsDisc | mensaje de alerta de terminos y condiciones">You Need to accept the Terms &
      Conditions</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="separator" align="end">
  <button mat-stroked-button color="accent" mat-dialog-close>
    <span i18n="CancelButtonOnSub | boton de cancelar en cancelar suscripcion">Close</span>
  </button>
  <button mat-flat-button color="primary" (click)="cancelSubscription()" [disabled]="!this.checkBoxFormGroup.valid">
    <mat-icon *ngIf="busy">
      <mat-spinner color="accent" diameter="20"></mat-spinner>
    </mat-icon>
    <span i18n="Accept|boton de aceptar">Accept</span>
  </button>
</mat-dialog-actions>