import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from './../../services/notification.service';
import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Notification } from 'src/app/models/notification.model';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/components/login-service';
import { MatSort } from '@angular/material/sort';
import { NotificationDialog } from 'src/app/layouts/header/header.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss']
})
export class NotificationsPage implements OnInit {

  user: Keycloak.KeycloakProfile;
  total: number;
  displayedColumns = ['tipoId','titulo',/*'copete','fechaCreado',*/ 'acciones'];
  dataSource: MatTableDataSource<Notification>;
  @ViewChild(MatSort) sort : MatSort;

  constructor(private notificationService: NotificationService,
              private loginService: LoginService,
              public dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {

    this.loginService.user$.subscribe(user => {
      this.user = user;
      if(user!=null)
      {
        let path = environment.apiBase + "/aplicaciones/" + environment.keycloakConfig.realm
        + "/tenants/" + environment.keycloakConfig.clientId
        + "/notificaciones/usuarioWeb?desplazamiento=0&limite=50";
        this.notificationService.obtenerTodos(path).subscribe(data => {
        this.dataSource = new MatTableDataSource(data.notificaciones);
        this.dataSource.sort = this.sort;
        this.total = data.total;
        });
      }
    });
  }

   openNotification(notification: Notification): void {
    this.readNotificationCheck(notification, true);
    const dialogRef = this.dialog.open(NotificationDialog, {
      height: '70%',
      data: notification
    });
  }

  readNotificationCheck(notification: any, state: boolean){
    this.notificationService.updateNotification(notification.id,"leido",state).subscribe(
      (response) =>  {
        this.ngOnInit();
      },
      (error) => console.log(error)
    );

  }

  deleteNotificationCheck(notification: any, state: boolean){
    this.notificationService.updateNotification(notification.id,"borrado",state).subscribe(
      (response) =>  {
        this.ngOnInit();
      },
      (error) => console.log(error)
    );

  }

  hideNotificationCheck(notification: any, state: boolean){
    this.notificationService.updateNotification(notification.id,"oculto",state).subscribe(
      (response) =>  {
        this.ngOnInit();
      },
      (error) => console.log(error)
    );
  }

}
