import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Category, Product} from '@models';
import {Observable} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  public ordenar = '';
  public tipo_orden = '';

  itemsPerPage: number;

  @Input()
  products: Product[];

  @Input()
  busqueda = false;

  @Input()
  destacadas: boolean;

  @Input()
  category: Category;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<Product[]>;


  constructor(private router: Router, private route: ActivatedRoute) {
    this.itemsPerPage = environment.itemsPerPage;
  }

  ngOnInit(): void {

  }

  OrderByNameToogle() {
    this.tipo_orden = 'ALFABETICO';
    if (this.ordenar === 'ASC') {
      this.ordenar = 'DESC';
    }
    else if(this.ordenar === 'DESC'){
      this.tipo_orden = null;
      this.ordenar = null;
    }
    else {
      this.ordenar = 'ASC';
    }

    this.router.navigate([], {
      queryParams: {
        ordenar: this.ordenar,
        tipo_orden: this.tipo_orden
      },
      queryParamsHandling: 'merge'
    });
  }

  OrderByPriceToogle() {
    this.tipo_orden = 'PRECIO';
    if (this.ordenar === 'ASC') {
      this.ordenar = 'DESC';
    }  else if(this.ordenar === 'DESC'){
      this.tipo_orden = null;
      this.ordenar = null;
    }
    else {
      this.ordenar = 'ASC';
    }
    this.router.navigate([], {
      queryParams: {
        ordenar: this.ordenar,
        tipo_orden: this.tipo_orden
      },
      queryParamsHandling: 'merge'
    });
  }

}
