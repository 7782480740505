import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-titulo',
  templateUrl: './banner-titulo.component.html',
  styleUrls: ['./banner-titulo.component.scss']
})
export class BannerTituloComponent implements OnInit {

  @Input() element:any;
  constructor() { }

  ngOnInit(): void {
  }

}
